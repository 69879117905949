import { UIButton, UICard, UITypography } from "../../_app/components";
import { createStylesheet, useTheme } from "../../_app/utils/styles";
import { usePromotion } from "../../brand/hooks";
import { useHasAnyFeatureEnabled } from "../../feature/hooks";
import { Feature } from "../../feature/types";
import { withWidget } from "./components/Widget";

const Advertisement = () => {
  const classes = useStyles();
  const theme = useTheme();
  const promotion = usePromotion();
  const hasFeature = useHasAnyFeatureEnabled(Feature.Advertisement);

  if (!hasFeature || !promotion) return null;
  return (
    <UICard
      padding="10px"
      height="191px"
      style={
        promotion.imageUrl && {
          backgroundImage: `url(${promotion.imageUrl})`,
          backgroundSize: "auto 100%",
          backgroundRepeat: "no-repeat",
          backgroundPositionX: "0%",
          minHeight: "fit-content",
        }
      }
    >
      <div className={classes.ctr}>
        {Boolean(promotion.title) && (
          <UITypography variant="h1" color="textPrimary" className={`${classes.shadow} ${classes.item}`}>
            {promotion.title}
          </UITypography>
        )}
        {Boolean(promotion.subtitle) && (
          <UITypography
            variant="h3"
            color="textPrimary"
            className={`${classes.shadow} ${classes.item}`}
            style={{ color: theme.palette.grey[500] }}
          >
            {promotion.subtitle}
          </UITypography>
        )}
        {Boolean(promotion.linkUrl) && (
          <UIButton
            variant="outlined"
            size="small"
            className={`${classes.btn} ${classes.item}`}
            onClick={() => window.open(promotion.linkUrl)}
          >
            {promotion.linkTitle || "View"}
          </UIButton>
        )}
        <div />
      </div>
    </UICard>
  );
};

const useStyles = createStylesheet((theme) => ({
  ctr: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column",
    alignItems: "flex-end",
    height: "100%",
    paddingRight: "20px",
    zIndex: 20,
  },
  item: {
    marginTop: "auto",
  },
  btn: {
    backgroundColor: "#fff",
    "&:hover": {
      backgroundColor: "#eee",
    },
  },
  shadow: {
    textShadow: `0 0 5px white,0 0 5px white,
                 0 0 5px white,0 0 5px white,
                 0 0 5px white,0 0 5px white,
                 0 0 5px white,0 0 5px white`,
  },
}));

export default withWidget(Advertisement, "advertisement");
