import { useAuthenticator } from "@aws-amplify/ui-react";
import { Navigate } from "react-router-dom";
import { useQueryClient } from "react-query";

export const CognitoLogout = () => {
  const { signOut } = useAuthenticator((context) => [context.user]);
  const queryClient = useQueryClient();
  signOut();
  queryClient.removeQueries();
  return <Navigate to="/login" />;
};
