import React, { ChangeEvent, useEffect, useMemo, useState } from "react";
import SelectDropdown, { Option } from "../../form/components/SelectDropdown";
import { useAccountsForLevel } from "../../user/hooks";
import { createStylesheet } from "../../_app/utils/styles";
import { UIButton, UIDialog, UIDialogActions, UIDialogContent, UIDialogTitle, UITypography } from "../../_app/components";
import { AccountLevel } from "../../account/utils";
import { getAccountOption } from "../../user/utils";
import { deleteLogicMonitorToken } from "../../logic-monitor/api";
import { useAccountContext } from "../../_app/providers/AccountHierarchyProvider";
import { useLocation, useNavigate } from "react-router-dom";
import { useFeedbackAlerts } from "../../_app/hooks";

interface SwitchAccountsDialogProps {
  title: string;
  open: boolean;
  setOpen: Function;
}

const SwitchAccountsDialog = ({ title, open, setOpen }: SwitchAccountsDialogProps) => {
  const classes = useStyles();
  const { refreshAccountHierarchy } = useAccountContext();
  const navigate = useNavigate();
  const location = useLocation();
  const { setFeedbackAlertSuccess } = useFeedbackAlerts();
  const PAGES_TO_REDIRECT = ["/usage-analysis", "/service-analysis"];

  const [accountSearchTerm, setAccountSearchTerm] = useState<string | undefined>();
  const [subAccountSearchTerm, setSubAccountSearchTerm] = useState<string | undefined>();

  const [selectedAccountId, setSelectedAccountId] = useState<string | undefined>();
  const [selectedSubAccountId, setSelectedSubAccountId] = useState<string | undefined>();

  const {
    data: accounts,
    fetchNextPage: fetchAccountNextPage,
    hasNextPage: hasAccountNextPage,
    isFetching: isAccountListFetching,
  } = useAccountsForLevel(`${AccountLevel.Account.id}`, { "search-term": accountSearchTerm });

  const {
    data: subAccounts,
    fetchNextPage: fetchSubAccountNextPage,
    hasNextPage: hasSubAccountNextPage,
    isFetching: isSubAccountListFetching,
  } = useAccountsForLevel(`${AccountLevel.SubAccount.id}`, {
    "search-term": subAccountSearchTerm,
    parent: selectedAccountId,
  });

  const reset = () => {
    setSelectedAccountId(undefined);
    setSelectedSubAccountId(undefined);
    setAccountSearchTerm(undefined);
    setSubAccountSearchTerm(undefined);
  };

  const accountsList = useMemo(() => {
    return accounts?.pages.flatMap((page) => page.list.map((account: any) => getAccountOption(account))) || [];
  }, [accounts]);

  const subAccountsList = useMemo(() => {
    return subAccounts?.pages.flatMap((page) => page.list.map((account: any) => getAccountOption(account))) || [];
  }, [subAccounts]);

  const isAccountSelected = selectedAccountId !== undefined;
  const hasMultipleAccounts = accountsList.length > 1 && (accountSearchTerm === undefined || accountSearchTerm === "");
  const hasSubAccounts = subAccountsList.length > 1 && (accountSearchTerm === undefined || accountSearchTerm === "");

  const disableSearch = (options: Option[], searchTerm: string | undefined) => {
    return options.length < 5 && (searchTerm === undefined || searchTerm === "");
  };

  useEffect(() => {
    if (accountsList.length === 1 && typeof accountsList[0].id === "string") {
      setSelectedAccountId(accountsList[0].id);
    }
  }, [hasMultipleAccounts, accountsList]);

  const handleClose = () => {
    setOpen(false);
    reset();
  };

  const handleSwitch = () => {
    setFeedbackAlertSuccess("Switch successful");
    // if switching context on /usage-analysis or /service-analysis, redirect to bills to choose correct bill period
    if (PAGES_TO_REDIRECT.includes(location.pathname)) {
      navigate("/bills");
    }
    deleteLogicMonitorToken();
    changeContext();
    reset();
  };

  const changeContext = () => {
    if (selectedSubAccountId) {
      refreshAccountHierarchy(selectedSubAccountId);
    } else if (selectedAccountId) {
      refreshAccountHierarchy(selectedAccountId);
    }
    setOpen(false);
  };

  return (
    <UIDialog open={open} onClose={handleClose} data-cy="switch-level-dialog">
      <UIDialogTitle className={classes.dialogTitle}>{title}</UIDialogTitle>
      <UIDialogContent className={classes.dialogContent}>
        <div className={classes.resetBar}>
          <UITypography>Select the level you wish to view</UITypography>
          <UIButton variant="outlined" color="primary" onClick={() => reset()}>
            Reset
          </UIButton>
        </div>
        {hasMultipleAccounts && (
          <SelectDropdown
            label="Account"
            data={accountsList}
            value={selectedAccountId ?? ""}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setSelectedAccountId(event.target.value);
            }}
            searchOn={(q: any) => {
              setSelectedSubAccountId(undefined);
              setSelectedAccountId(undefined);
              setAccountSearchTerm(q || undefined);
            }}
            ctrClass={classes.textField}
            nextPage={fetchAccountNextPage}
            hasNextPage={hasAccountNextPage}
            isFetching={isAccountListFetching}
            infiniteScroll
            hasSearch={!disableSearch(accountsList, accountSearchTerm)}
            hasNoOptionsText
          />
        )}
        {isAccountSelected && hasSubAccounts && (
          <SelectDropdown
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              setSelectedSubAccountId(event.target.value);
            }}
            value={selectedSubAccountId ?? ""}
            label="Sub Account"
            data={subAccountsList}
            searchOn={(q: any) => {
              setSelectedSubAccountId(undefined);
              setSubAccountSearchTerm(q || undefined);
            }}
            ctrClass={classes.textField}
            nextPage={fetchSubAccountNextPage}
            hasNextPage={hasSubAccountNextPage}
            isFetching={isSubAccountListFetching}
            infiniteScroll
            hasSearch={!disableSearch(subAccountsList, subAccountSearchTerm)}
            hasNoOptionsText
          />
        )}
      </UIDialogContent>
      <UIDialogActions className={classes.dialogActions}>
        <UIButton
          onClick={handleSwitch}
          variant="contained"
          color="primary"
          fullWidth
          data-cy="switch-level-dialog-button"
          disabled={!isAccountSelected}
        >
          Switch
        </UIButton>
      </UIDialogActions>
    </UIDialog>
  );
};

const useStyles = createStylesheet((theme) => ({
  dialogTitle: {
    textAlign: "center",
    backgroundColor: theme.palette.tertiary.dark,
    color: theme.palette.tertiary.contrastText,
  },
  dialogContent: {
    width: "600px",
    padding: theme.spacing(6),
    paddingBottom: theme.spacing(0),
  },
  dialogActions: {
    padding: theme.spacing(0),
    margin: theme.spacing(6),
    marginBottom: theme.spacing(4),
  },
  resetBar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "2rem",
  },
  textField: {
    marginBottom: theme.spacing(3),
  },
}));

export default SwitchAccountsDialog;
