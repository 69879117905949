import api from "../_app/api";
import { WdrStatus, WdrStatusChange } from "./types";
import { Page } from "../_app/api/types";

export const getWdrStatus = async (accountId: string, page?: number, limit?: number, queries?: any[], assets?: string[]) => {
  const { data }: { data: Page<WdrStatus> } = await api({
    method: "GET",
    url: `/mobile-assets/accounts/${accountId}/wdr`,
    serialize: true,
    params: { page, limit, queries, "asset-ids": assets },
  });
  return data;
};

export const getWdrStatusCostCentre = async (
  costCentreId: string,
  page?: number,
  limit?: number,
  queries?: any[],
  assets?: string[],
) => {
  const { data }: { data: Page<WdrStatus> } = await api({
    method: "GET",
    url: `/mobile-assets/cost-centres/${costCentreId}/wdr`,
    serialize: true,
    params: { page, limit, queries, "asset-ids": assets },
  });
  return data;
};

export const getWdrStatusForAsset = async (assetId: string) => {
  const { data }: { data: WdrStatus } = await api({
    method: "GET",
    url: `/mobile-assets/${assetId}/wdr`,
    serialize: true,
  });
  return data;
};

export const getWdrStatusForAssetCostCentre = async (costCentreId: string, assetId: string) => {
  const { data }: { data: WdrStatus } = await api({
    method: "GET",
    url: `/mobile-assets/${assetId}/cost-centres/${costCentreId}/wdr`,
    serialize: true,
  });
  return data;
};

export const setWdrStatus = async (accountId: string, values: any) => {
  const operations = [];
  for (let entry of values.entries()) {
    operations.push({
      assetId: entry[0],
      capped: entry[1],
    });
  }
  const { data }: { data: string } = await api({
    method: "PUT",
    url: `/mobile-assets/accounts/${accountId}/wdr`,
    serialize: true,
    data: operations,
  });
  return data;
};

export const setWdrStatusCostCentre = async (costCentreId: string, values: any) => {
  const operations = [];
  for (let entry of values.entries()) {
    operations.push({
      assetId: entry[0],
      capped: entry[1],
    });
  }
  const { data }: { data: string } = await api({
    method: "PUT",
    url: `/mobile-assets/cost-centres/${costCentreId}/wdr`,
    serialize: true,
    data: operations,
  });
  return data;
};

export const setWdrStatusForAsset = async (statusChange: WdrStatusChange) => {
  const { data }: { data: string } = await api({
    method: "PUT",
    url: `/mobile-assets/${statusChange.assetId}/wdr`,
    serialize: true,
    data: statusChange,
    params: { capped: statusChange.capped },
  });

  return data;
};

export const setWdrStatusForAssetCostCentre = async (costCentreId: string, statusChange: WdrStatusChange) => {
  const { data }: { data: string } = await api({
    method: "PUT",
    url: `/mobile-assets/${statusChange.assetId}/cost-centres/${costCentreId}/wdr`,
    serialize: true,
    data: statusChange,
    params: { capped: statusChange.capped },
  });

  return data;
};
