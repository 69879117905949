import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { GetRolesResponse, Role } from "../types";
import Main from "../../_app/layouts/Main";
import { useAllRoles, useRoleDelete } from "../hooks";
import { permissionCodes } from "../../permission/hooks";
import { formatDate } from "../../_app/utils/format";
import ManageRolesDeleteButton from "../components/ManageRolesDeleteButton";
import { rowParser } from "../../_app/components/Table/helpers";
import UILoader from "../../_app/components/UILoader";
import ManageUsersTabBar from "../../user/components/ManageUsersTabBar";
import { createStylesheet } from "../../_app/utils/styles";
import { UIButton, UITabPanel } from "../../_app/components";
import UIDataTable, { UIDataTableState } from "../../_app/components/Table/UIDataTable";
import { Add as AddIcon, Create as EditIcon } from "../../_app/components/icons";

export interface TableConfig {
  title: string;
  columns: string[];
}

const tableConfig: TableConfig = {
  title: "Roles",
  columns: ["Role name", "Created by", "Assigned to", "Created date", "", ""],
};

export const ManageRoles = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const selectedTab = "roles";
  const rowCountOptions = [25, 50, 100];
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = useState(rowCountOptions[0]);
  const [parsedWithCreatedBy, setParsed] = useState<any>([]);
  const columns = tableConfig.columns;

  const mapRows = (roles: GetRolesResponse) => {
    const handleRoleEdit = (role: Role) => navigate(`/roles/${role?.id}`);
    const handleRoleDelete = (role: Role) => executeRoleDelete(role);

    return roles?.list?.map((r: Role) => {
      return rowParser([
        r.name,
        r.createdByName,
        `${r.assignedTo || 0} Users`,
        formatDate(r.createdAt.toString(), "dd-MM-yyyy HH:mm"),
        <UIButton
          variant="text"
          color="inherit"
          className={classes.rowButton}
          onClick={() => handleRoleEdit(r)}
          startIcon={<EditIcon />}
        >
          Edit
        </UIButton>,
        <ManageRolesDeleteButton role={r} deleteRole={handleRoleDelete} />,
      ]);
    });
  };

  const {
    data: roles,
    isFetching,
    isError,
  } = useAllRoles(page, rowCount, {
    onSuccess: (roles: GetRolesResponse) => {
      setParsed(mapRows(roles));
    },
  });

  const { mutate: executeRoleDelete } = useRoleDelete();

  const handleTableChange = (action: string, state: UIDataTableState) => {
    switch (action) {
      case "changePage":
        setPage(state.page);
        break;
      case "changeRowsPerPage":
        setRowCount(state.rowsPerPage);
        break;
      default:
        break;
    }
  };

  return (
    <Main
      title={
        <>
          Manage Roles
          <UIButton
            variant="contained"
            color="primary"
            data-cy="add-role-btn"
            startIcon={<AddIcon />}
            onClick={() => navigate("/role/new")}
          >
            <span className={classes.addBtnText}>Add Role</span>
          </UIButton>
        </>
      }
      data-cy="manage-roles-page"
      accessPermission={permissionCodes.USERS_MANAGE}
    >
      <ManageUsersTabBar selectedTab={selectedTab}>
        <UITabPanel className={classes.ctr} value={selectedTab}>
          {isFetching && !isError ? (
            <div className={classes.loader}>
              <UILoader />
            </div>
          ) : (
            <div className={classes.tableCtr} data-cy="roles-table">
              <UIDataTable
                title={tableConfig.title}
                data={parsedWithCreatedBy || []}
                columns={columns || []}
                options={{
                  onTableChange: handleTableChange,
                  pagination: true,
                  page,
                  rowsPerPage: rowCount,
                  rowsPerPageOptions: rowCountOptions,
                  count: roles?.total,
                  download: false,
                  elevation: 1,
                  print: false,
                  responsive: "standard",
                  selectToolbarPlacement: "none",
                  filter: false,
                  sort: false,
                  search: false,
                  serverSide: true,
                  selectableRows: "none",
                  rowHover: true,
                  setTableProps: () => ({ size: "small" }),
                  viewColumns: false,
                  setRowProps: (row) => ({
                    "data-cy": `role-id-${row[0]}`,
                  }),
                }}
              />
            </div>
          )}
        </UITabPanel>
      </ManageUsersTabBar>
    </Main>
  );
};

const useStyles = createStylesheet((theme) => ({
  ctr: {
    padding: "0",
  },
  tableCtr: {
    "& div:nth-of-type(3)": {
      padding: "0px",
    },
    "& td:nth-of-type(10)": {
      textAlign: "right",
      padding: "0px",
    },
    "& td:nth-of-type(12)": {
      textAlign: "right",
    },
  },
  rowButton: {
    fontWeight: "normal",
    color: theme.palette.tertiary.main,
  },
  loader: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "calc(50vh - 200px)",
  },
  addBtnText: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

export default ManageRoles;
