import { Outlet } from "react-router-dom";
import Main from "../../_app/layouts/Main";
import { UIAlert } from "../../_app/components";
import ManageCostCentresTabBar from "../components/ManageCostCentresTabBar";
import NewCostCentre from "../components/NewCostCentre";
import CostCentreList from "../components/CostCentreList";
import { useHasAnyFeatureEnabled } from "../../feature/hooks";
import { permissionCodes, useHasPermission } from "../../permission/hooks";
import { createStylesheet } from "../../_app/utils/styles";
import { Feature } from "../../feature/types";

export const ManageCostCentres = () => {
  const classes = useStyles();
  const hasCostCentreManagementFeature = useHasAnyFeatureEnabled(Feature.CostCentreManagement);
  const hasCostCentreManagementPermission = useHasPermission(permissionCodes.COST_CENTRE_MANAGE);

  return (
    <Main
      title={
        <>
          Manage Cost Centres
          {hasCostCentreManagementFeature && hasCostCentreManagementPermission && <NewCostCentre />}
        </>
      }
      data-cy="manage-cost-centres-page"
      needSelectedAccount
      feature={Feature.CostCentreManagement}
      accessPermission={permissionCodes.COST_CENTRE_MANAGE}
    >
      <ManageCostCentresTabBar selectedTab="manage-cost-centres">
        <UIAlert severity="info" className={classes.info}>
          Cost centres allow you to organise and group your charges. A report providing a breakdown of you charges for cost centre
          costing purposes can be downloaded from the Bill Explorer every month.
        </UIAlert>
        <div className={classes.ctr}>
          <CostCentreList />
        </div>
      </ManageCostCentresTabBar>
      <Outlet />
    </Main>
  );
};

const useStyles = createStylesheet((theme) => ({
  ctr: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    gap: "20px",
  },
  top: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  info: {
    alignItems: "center",
    width: "fit-content",
    marginBottom: theme.spacing(3),
  },
}));

export default ManageCostCentres;
