import { UIAlert, UICard, UIFormControlLabel, UISwitch } from "../../_app/components";
import UILoader from "../../_app/components/UILoader";
import Main from "../../_app/layouts/Main";
import { createStylesheet } from "../../_app/utils/styles";
import { AccountLevel } from "../../account/utils";
import { DevelopmentFeature } from "../../development-feature/types";
import { permissionCodes } from "../../permission/hooks";
import ManageBundleAlertsTabBar from "../components/BundleAlerts/ManageBundleAlertsTabBar";
import { useBundleAlertGlobal } from "../hooks";

export const BundleAlertsGlobal = () => {
  const classes = useStyles();

  const { data: globalBundleAlertEnabled, isFetching, error } = useBundleAlertGlobal();

  return (
    <Main
      title="Global Bundle Alert"
      data-cy="support-bundle-alerts-global-page"
      developmentFeature={DevelopmentFeature.GlobalBundleAlerts}
      accessPermission={permissionCodes.ALERTS_ACCESS_FEATURE}
      minimumAccountLevel={AccountLevel.Account}
      needSelectedAccount={true}
    >
      <ManageBundleAlertsTabBar selectedTab="bundle-alerts-global">
        {isFetching ? (
          <div className={classes.loader}>
            <UILoader />
          </div>
        ) : error ? (
          <UIAlert severity="error" className={classes.alert}>
            Cannot get Global Bundle Alert for your account.
          </UIAlert>
        ) : (
          <UICard>
            <UIFormControlLabel
              data-cy="bundle-alerts-global-switch-label"
              title="Updating the Global Bundle Alert is not allowed at this moment."
              control={<UISwitch checked={!!globalBundleAlertEnabled} disabled={true} data-cy="bundle-alerts-global-switch" />}
              label={!!globalBundleAlertEnabled ? "Enabled" : "Disabled"}
              sx={{ ml: 1 }}
            />
          </UICard>
        )}
      </ManageBundleAlertsTabBar>
    </Main>
  );
};

const useStyles = createStylesheet((theme) => ({
  loader: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "calc(50vh - 200px)",
  },
  actions: {
    marginBottom: theme.spacing(2),
  },
  tableCtr: {},
  alert: {
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
}));

export default BundleAlertsGlobal;
