import { useMutation, useQuery, useQueryClient } from "react-query";
import { AjaxResponse } from "../_app/api";
import {
  getWdrStatus,
  getWdrStatusCostCentre,
  getWdrStatusForAsset,
  getWdrStatusForAssetCostCentre,
  setWdrStatus,
  setWdrStatusCostCentre,
  setWdrStatusForAsset,
  setWdrStatusForAssetCostCentre,
} from "./api";
import { WdrStatus, WdrStatusChange } from "./types";
import { useFeedbackAlerts } from "../_app/hooks";
import { useAccountContext } from "../_app/providers/AccountHierarchyProvider";
import { UserType } from "../user-level/types";
import { Page } from "../_app/api/types";

export function useWdrStatus(page: number, limit: number, queries?: any[], assets?: string[], options = {}) {
  const { setFeedbackAlertError } = useFeedbackAlerts();
  const { contextId, userType } = useAccountContext();

  const callback = userType === UserType.COST_CENTRE ? getWdrStatusCostCentre : getWdrStatus;

  return useQuery<Page<WdrStatus>, AjaxResponse>(
    ["wdr-status", { page, limit, queries, assets }],
    () => callback(contextId, page, limit, queries, assets),
    {
      ...options,
      staleTime: 0,
      onError: (error) => {
        setFeedbackAlertError(error?.data?.message);
      },
    },
  );
}

export function useWdrStatusForAsset(assetId: string, options: any = {}) {
  const { contextId, userType } = useAccountContext();

  return useQuery<WdrStatus, AjaxResponse>(
    ["wdr-status", { assetId }],
    () =>
      userType === UserType.COST_CENTRE ? getWdrStatusForAssetCostCentre(contextId, assetId) : getWdrStatusForAsset(assetId),
    {
      ...options,
    },
  );
}

export function useSetWdrStatus(values: any, options: any = {}) {
  const { setFeedbackAlertError, setFeedbackAlertSuccess } = useFeedbackAlerts();

  const { contextId, userType } = useAccountContext();
  const callback = userType === UserType.COST_CENTRE ? setWdrStatusCostCentre : setWdrStatus;

  return useMutation<string, AjaxResponse, any>(() => callback(contextId, values), {
    ...options,
    onSuccess: (data) => {
      setFeedbackAlertSuccess(data);
      options?.onSuccess?.();
    },
    onError: (error) => {
      setFeedbackAlertError(error?.data?.message);
    },
  });
}

export function useSetWdrStatusForAsset(assetId: string, options: any = {}) {
  const { setFeedbackAlertError } = useFeedbackAlerts();
  const { contextId, userType } = useAccountContext();
  const queryClient = useQueryClient();

  return useMutation<string, AjaxResponse, any>(
    (statusChange: WdrStatusChange) =>
      userType === UserType.COST_CENTRE
        ? setWdrStatusForAssetCostCentre(contextId, statusChange)
        : setWdrStatusForAsset(statusChange),
    {
      ...options,
      onError: (error) => {
        setFeedbackAlertError(error?.data?.message);
      },
      onSuccess: () => {
        queryClient.invalidateQueries(["wdr-status", { assetId }]);
      },
    },
  );
}
