import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ArrowForward as ArrowForwardIcon, CloudDownload as CloudDownloadIcon } from "../../_app/components/icons";
import { UIButton, UICard, UITypography } from "../../_app/components";
import { withWidget } from "./components/Widget";
import { useLastBill } from "../../billing/hooks";
import { useUserLevelMap } from "../../user-level/hooks";
import { getLastBillPdf } from "../../billing/api";
import UILoader from "../../_app/components/UILoader";
import { downloadFile, extractFilenameFromHeaders } from "../../_app/utils";
import { formatDate, formatNumber } from "../../_app/utils/format";
import { useFeedbackAlerts } from "../../_app/hooks";
import { permissionCodes, useHasPermission } from "../../permission/hooks";
import { filtersIdMap } from "../../filter/utils";
import { createStylesheet } from "../../_app/utils/styles";

interface LatestBillProps {
  userType?: string;
}

const LatestBill = ({ userType = "DEFAULT" }: LatestBillProps) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { setFeedbackAlertError } = useFeedbackAlerts();
  const { data: billDetail, isFetching, isSuccess } = useLastBill();
  const userLevels = useUserLevelMap();
  const hasBillingManagePermission = useHasPermission(permissionCodes.BILLING_MANAGE);
  const canDownloadInvoice = Boolean(billDetail?.billOutputTypes.includes("INVOICE"));
  const canViewMore = hasBillingManagePermission;

  const NameValue = ({ name, value }: { name: string; value: string | undefined }) => {
    return (
      <div>
        <UITypography variant="body1" className={classes.name}>
          {name}
        </UITypography>
        <UITypography variant="body1" className={classes.value}>
          {value}
        </UITypography>
      </div>
    );
  };

  const BillRow = ({ name, value }: { name: string; value: string | undefined }) => {
    return (
      <div className={classes.row}>
        <UITypography variant="h3">{name}</UITypography>
        <UITypography variant="h3">{value}</UITypography>
      </div>
    );
  };

  const [showLoading, setShowLoading] = useState(false);
  const onDownloadFileClick = async (type: string) => {
    await getLastBillPdf(billDetail?.systemInvoiceRef ?? "")
      .then((response) => {
        const filename = extractFilenameFromHeaders(response) || `${type}-export.pdf`;
        downloadFile(response?.data, filename);
      })
      .catch((err) => {
        setFeedbackAlertError(err?.data?.message || "Unable to download file");
      });
  };

  const COST_CENTRE: string = "COST_CENTRE";
  const INDIVIDUAL: string = "INDIVIDUAL";
  const SUB_ACCOUNT: string = "SUB_ACCOUNT";

  const totalTextFor = (userType: string) => {
    switch (userType) {
      case COST_CENTRE:
        return "Cost Centre Total";
      case SUB_ACCOUNT:
        return `${userLevels?.[35]?.name} Total`;
      default:
        return "Total";
    }
  };

  const hideWidgetFor = (userLevel: any, widgets: any) => {
    if ([COST_CENTRE, INDIVIDUAL, SUB_ACCOUNT].includes(userLevel)) {
      return;
    } else {
      return { ...widgets };
    }
  };

  const title = (filename: string = "") => (
    <div className={classes.row}>
      <UITypography variant="h3" data-cy="bill-summary-title">
        Latest Bill
      </UITypography>
      {hideWidgetFor(
        userType,
        !isFetching && isSuccess && canDownloadInvoice ? (
          <UIButton
            color="primary"
            key="title2"
            onClick={async () => {
              setShowLoading(true);
              await onDownloadFileClick(filename).then(() => {
                setShowLoading(false);
              });
            }}
            data-cy="bill-summary-download"
            startIcon={showLoading ? <UILoader className={classes.loadingSpinner} /> : <CloudDownloadIcon />}
          >
            Download PDF
          </UIButton>
        ) : (
          <></>
        ),
      )}
    </div>
  );

  const errorMessage = (
    <UIButton variant="contained" disabled className={classes.error}>
      There is no bill to display
    </UIButton>
  );

  return (
    <UICard isFetching={isFetching} isSuccess={isSuccess} errorMessage={errorMessage} padding="0px">
      <div className={classes.content}>
        {title(billDetail?.invoiceNumber)}
        <div className={classes.row}>
          <NameValue name="Invoice Date" value={formatDate(billDetail?.billDate)} />
          {Boolean(billDetail?.dueDate) && <NameValue name="Payment Due" value={formatDate(billDetail?.dueDate)} />}
        </div>
        <BillRow name={totalTextFor(userType)} value={formatNumber(billDetail?.gross, 2, "£")} />
        <div>
          {canViewMore && (
            <UIButton
              variant="contained"
              color="primary"
              data-cy="more-details-button"
              className={classes.button}
              endIcon={<ArrowForwardIcon />}
              fullWidth
              onClick={() => {
                const period = billDetail?.period;
                navigate(
                  `/bills/period-details/${period}/${billDetail?.systemInvoiceRef}?${filtersIdMap?.BILL_PERIOD}=${period}`,
                  { state: { fromHome: true, periodId: period } },
                );
              }}
            >
              Explore Bill
            </UIButton>
          )}
        </div>
      </div>
    </UICard>
  );
};

const useStyles = createStylesheet((theme) => ({
  content: {
    padding: theme.spacing(3),
  },
  loadingSpinner: {
    margin: "0px 10px",
    maxWidth: "20px",
    maxHeight: "20px",
  },
  button: {
    width: "fit-content",
    marginRight: theme.spacing(1),
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: theme.spacing(3),
  },
  name: {
    float: "left",
    fontWeight: "bold",
    paddingRight: theme.spacing(1),
  },
  value: {
    float: "left",
  },
  error: {
    marginTop: "22px",
    marginBottom: "22px",
  },
}));

export default withWidget(LatestBill, "latest-bill");
