import { useNavigate } from "react-router-dom";
import { UIAlert, UIButton, UICheckbox, UIFormControlLabel, UIGrid, UITextField, UITypography } from "../../../_app/components"; // Updated imports
import { useBillLimitPrefs } from "../../../asset/hooks";
import { useValidation } from "../../../form/hooks";
import { isEmail } from "../../../_app/utils";
import UILoader from "../../../_app/components/UILoader";
import { createStylesheet } from "../../../_app/utils/styles";
import { ChangeEvent, useEffect, useState } from "react";
import { useAccountContext } from "../../../_app/providers/AccountHierarchyProvider";

interface Props {
  callback: (sms: boolean, email: boolean, smsValue?: string, emailValue?: string) => void;
  assetId?: string;
  cli?: string;
}

export const BillLimitAlertsUpdateForm = (props: Props) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { callback } = props;
  const isForCli = !!props.assetId;
  const { selectedAccount } = useAccountContext();
  const accountCode = selectedAccount?.code;
  const [sms, setSms] = useState(false);
  const [email, setEmail] = useState(false);
  const [smsValue, setSmsValue] = useState<string | undefined>("");
  const [emailValue, setEmailValue] = useState<string | undefined>("");

  const { data: prefs, isFetching, isError } = useBillLimitPrefs(props.assetId);

  useEffect(() => {
    if (!!prefs) {
      setSms(prefs?.sendSms);
      setEmail(prefs?.sendEmail);
      setSmsValue(prefs?.notifyMobileNumber);
      setEmailValue(prefs?.notifyEmailAddress);
    }
  }, [prefs]);

  // Validation
  const validationConfig: any = {
    smsValue: { required: false },
    emailValue: {
      required: false,
      validate: (val: any) => {
        if (val && !isEmail(val)) return "Invalid email";
      },
    },
  };

  const { validationErrors, validateSingle, validateAll } = useValidation(validationConfig, { smsValue, emailValue });

  return (
    <>
      <UIAlert severity="info" className={classes.info}>
        <div>
          <strong>Bill Limit notification settings for {isForCli ? `CLI ${props.cli}` : `account ${accountCode}`}</strong>
        </div>
        {isForCli ? (
          <div>
            Notifications for this bill limit will be automatically sent to the mobile number by SMS. You can add additional
            notifications below.
          </div>
        ) : (
          <div>
            Bill limit notifications for all eligible mobile numbers on the account will be automatically sent to the mobile
            number by SMS. You can add additional notifications below. These defaults can be overridden via the CLI tab.
          </div>
        )}
        <div>
          Early warning <strong>80%</strong>, Late warning <strong>90%</strong>, Limit breached <strong>100%</strong>.
        </div>
        <div>
          We will use reasonable endeavours to notify you as soon as we become aware that the above levels have been breached.
          However, in certain circumstances that are beyond our control, such as delays in call data being received from the
          network, the alerts may be a sent late.
        </div>
      </UIAlert>
      {isFetching && (
        <div className={classes.loader}>
          <UILoader />
        </div>
      )}
      {!isError && !isFetching && (
        <>
          <UIGrid container spacing={2}>
            <UIGrid size={{ xs: 12, md: 6 }}>
              <UIFormControlLabel
                className={classes.row}
                key="sms"
                label={<UITypography variant="subtitle1">SMS alerts sent to mobile number:</UITypography>}
                labelPlacement="start"
                control={
                  <UICheckbox
                    color="primary"
                    checked={sms}
                    onChange={(ev: any, value: boolean) => {
                      setSms(value);
                    }}
                  />
                }
              />
            </UIGrid>
            <UIGrid size={{ xs: 12, md: 6 }}>
              {sms && (
                <UITextField
                  name="smsValue"
                  label="Add phone number"
                  type="text"
                  value={smsValue}
                  variant="outlined"
                  size="small"
                  fullWidth
                  error={Boolean(validationErrors.smsValue)}
                  helperText={validationErrors.smsValue}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setSmsValue(event.target.value);
                    validateSingle(event.target.name, event.target.value);
                  }}
                />
              )}
            </UIGrid>
          </UIGrid>
          <UIGrid container spacing={2}>
            <UIGrid size={{ xs: 12, md: 6 }}>
              <UIFormControlLabel
                className={classes.row}
                key="email"
                label={<UITypography variant="subtitle1">Email alerts sent to:</UITypography>}
                labelPlacement="start"
                control={
                  <UICheckbox
                    color="primary"
                    checked={email}
                    onChange={(ev: any, value: boolean) => {
                      setEmail(value);
                    }}
                  />
                }
              />
            </UIGrid>
            <UIGrid size={{ xs: 12, md: 6 }}>
              {email && (
                <UITextField
                  name="emailValue"
                  label="Add Email"
                  type="text"
                  value={emailValue}
                  variant="outlined"
                  size="small"
                  fullWidth
                  error={Boolean(validationErrors.emailValue)}
                  helperText={validationErrors.emailValue}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    setEmailValue(event.target.value);
                    validateSingle(event.target.name, event.target.value);
                  }}
                />
              )}
            </UIGrid>
          </UIGrid>
          <UIGrid size={{ xs: 12, md: 12 }} className={classes.save}>
            <UIButton
              variant="contained"
              color="primary"
              data-cy="save-button"
              onClick={() => {
                const vErrors = validateAll();
                if (vErrors.length) return;
                callback(sms, email, smsValue, emailValue);
              }}
            >
              Save
            </UIButton>
            {isForCli && (
              <UIButton variant="outlined" color="primary" onClick={() => navigate(-1)}>
                Cancel
              </UIButton>
            )}
          </UIGrid>
        </>
      )}
    </>
  );
};

const useStyles = createStylesheet(() => ({
  title: {
    marginBottom: 10,
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "40vh",
  },
  info: {
    alignItems: "center",
    marginBottom: "30px",
    "& div:not(:last-child)": {
      marginBottom: "8px",
    },
  },
  row: {
    width: "100%",
    textAlign: "left",
    justifyContent: "space-between",
    marginLeft: 5,
  },
  save: {
    paddingTop: "30px",
    paddingLeft: 4,
    display: "flex",
    gap: 15,
  },
}));

export default BillLimitAlertsUpdateForm;
