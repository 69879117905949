import api from "../_app/api";
import { DevelopmentFeature } from "./types";

export const getEnabledDevelopmentFeatures = async () => {
  const { data }: { data: DevelopmentFeature[] } = await api({
    method: "GET",
    url: "/development-features/my",
  });

  return data;
};
