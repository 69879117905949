import api from "../_app/api";
import { Feature } from "./types";

export const getEnabledFeatures = async () => {
  const { data }: { data: Feature[] } = await api({
    method: "GET",
    url: "/features",
  });

  return data;
};
