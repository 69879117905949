import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useBillLimit, useClearBillLimit, useUpdateBillLimit } from "../hooks";
import Main from "../../_app/layouts/Main";
import StatusChip from "../../_app/components/StatusChip";
import { rowParser } from "../../_app/components/Table/helpers";
import UIConfirm from "../../_app/components/UIConfirm";
import UILoader from "../../_app/components/UILoader";
import { BillLimit as BillLimitType } from "../types";
import { useFeedbackAlerts, useStore } from "../../_app/hooks";
import { formatCurrency, to2Digits } from "../../_app/utils/format";
import { useHasAnyFeatureEnabled } from "../../feature/hooks";
import { useValidation } from "../../form/hooks";
import { createStylesheet } from "../../_app/utils/styles";
import {
  UIAlert,
  UIButton,
  UICard,
  UIFormControl,
  UIGrid,
  UIInputAdornment,
  UIMenuItem,
  UISelect,
  UITextField,
  UITypography,
} from "../../_app/components";
import UIDataTable, { UIDataTableState } from "../../_app/components/Table/UIDataTable";
import { Feature } from "../../feature/types";
import { UserType } from "../../user-level/types";

export const BillLimit = () => {
  const location = useLocation();
  const classes = useStyles();
  const { dispatch } = useStore();
  const { setFeedbackAlertError } = useFeedbackAlerts();
  const rowCountOptions = [25, 50, 100];
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = useState(rowCountOptions[0]);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const navigate = useNavigate();
  const assets = location.state?.assets;
  const filters = location.state?.filters;

  useEffect(() => {
    if (!assets?.length && !filters) {
      setFeedbackAlertError("No eligible mobile numbers selected");
      navigate({
        pathname: "/mobile-management",
      });
    }
  }, [assets, dispatch, filters, navigate, setFeedbackAlertError]);

  // Bill Limit Value
  const [billLimitValue, setNewBillLimit] = useState("");
  const validationConfig: any = {
    billLimit: {
      required: true,
      validate: (val: any) => {
        const regex = /^\d+(\.\d{0,2})?$/;
        if (val < 0) return "Cannot be less that 0";
        if (val > 999999.99) return "Exceeded maximum limit";
        if (!regex.test(val)) return "Has to be a 2 decimal number";
      },
    },
  };
  const { validationErrors, validateSingle } = useValidation(validationConfig, {
    billLimitValue,
  });

  const { data: assetsResp, isFetching } = useBillLimit(
    assets?.map((a: any) => a?.id),
    page,
    rowCount,
    filters,
  );

  const { mutate: executeClearBillLimit, isLoading: isClearing } = useClearBillLimit({
    onSuccess: () => {
      navigate(-1);
    },
  });

  const { mutate: executeBillLimitUpdate, isLoading: isUpdating } = useUpdateBillLimit({
    onSuccess: () => {
      navigate(-1);
    },
  });

  const actionList = [
    {
      id: "clear-limits",
      enabled: useHasAnyFeatureEnabled(Feature.BillLimit),
      value: "Clear limits",
      dialogTitle: "Clear limits Confirmation",
      dialogText: [
        <UITypography variant="body2" gutterBottom key="warning1">
          Please carefully consider removing bill limit(s).
        </UITypography>,
        <UITypography variant="body2" gutterBottom key="warning2">
          The bill limit cannot be re-instated for the current month once removed.
        </UITypography>,
        <UITypography variant="body2" key="warning3">
          Any capped charges will become chargeable upon removal of the bill limit.
        </UITypography>,
      ],
      canSubmit: () => !isLoading,
      action: () => {
        executeClearBillLimit({
          assetIds: assets?.map((a: any) => a?.id),
          filters,
        });
      },
    },
    {
      id: "set-limit",
      enabled: useHasAnyFeatureEnabled(Feature.BillLimit),
      value: "Set limit from next month",
      dialogTitle: "Set limit from the 1st of next month",
      dialogText: [
        <UITypography variant="body2" gutterBottom key="warning4">
          The new bill limit will come into effect from the 1st of next month. <br />
          By submitting this request, you agree and acknowledge the following:
        </UITypography>,
        <ul key="warning5">
          <li>
            <UITypography variant="body2" gutterBottom>
              Where necessary some services will be restricted to minimise the risk of further cost - Emergency services will not
              be affected by any applicable restrictions.
            </UITypography>
          </li>
          <li>
            <UITypography variant="body2" gutterBottom>
              Calls that carry a third-party service, such as directory enquiries and other non-geographical numbers, will still
              be charged. Only network access fees will be limited.
            </UITypography>
          </li>
          <li>
            <UITypography variant="body2" gutterBottom>
              MPAY and other non-mobile service charges are excluded completely from the Bill Limit service and these will remain
              chargeable in full.
            </UITypography>
          </li>
        </ul>,
        <UITypography variant="body2" key="warning6">
          In the event you have concerns with the terms of this service, please be aware that we offer an alternative and highly
          adaptive usage alert cost management service that may be suitable for your requirements.
        </UITypography>,
      ],
      canSubmit: () => {
        return !isLoading && !validationErrors.billLimit && billLimitValue;
      },
      action: () => {
        if (!validationErrors.billLimit && billLimitValue) {
          executeBillLimitUpdate({
            assetIds: assetsResp?.content?.map((a: any) => a?.assetId),
            filters,
            operations: [{ limit: billLimitValue }],
          });
        }
      },
    },
  ];
  const defaultAction = actionList[1] || actionList[0];
  const [selectedActionId, setSelectedActionId] = useState<string>(defaultAction.id);
  const getActionFromActionId = (id: string) => actionList?.find((a) => a?.id === id);
  const getActionFromActionValue = (val: string) => actionList?.find((a) => a?.value === val);

  const selectedAction = getActionFromActionId(selectedActionId);

  const handleActionChange = (event: any) => {
    const action = getActionFromActionValue(event.target.value);
    setSelectedActionId(action?.id || "");
  };

  const handleSubmit = () => {
    if (selectedAction?.canSubmit()) setOpenDialog(true);
  };

  const handleTableChange = (action: string, state: UIDataTableState) => {
    switch (action) {
      case "changePage":
        setPage(state.page);
        break;
      case "changeRowsPerPage":
        setRowCount(state.rowsPerPage);
        break;
      default:
        break;
    }
  };

  const parsed =
    assetsResp?.content?.map((item: BillLimitType) => {
      return rowParser([
        item.cli,
        !item.eligible ? <StatusChip status="FAILED" label="Not Eligible" /> : " ",
        item.tag,
        item.current ? formatCurrency(item.current) : "",
        item.nextMonth ? formatCurrency(item.nextMonth) : "",
      ]);
    }) || [];

  const renderError = () => {
    if (assets?.length) return null;
    if (filters) return null;
    return <UIAlert severity="error">No mobile numbers selected</UIAlert>;
  };

  const isLoading = isFetching || isUpdating || isClearing;

  return (
    <Main
      title="Bill Limit"
      data-cy="bill-limit-page"
      needSelectedAccount={false}
      feature={Feature.BillLimit}
      showNav={false}
      showToolbar={false}
      showFooter={false}
      allowedUserTypes={[UserType.COST_CENTRE, UserType.ACCOUNT]}
    >
      {renderError() || (
        <UIGrid size={{ xs: 12, md: 12 }}>
          <UIGrid container>
            <div className={classes.actionItem}>
              <UIAlert severity="warning" className={classes.alert}>
                This service allows you to set a bill limit (£) against each individual mobile number. <br />
                Only mobile numbers contracted from 1st October 2018 are eligible for bill limits. <br />
                Bill limits exclude line rentals.
              </UIAlert>
            </div>
            <UICard className={classes.actionsCtr}>
              <UIFormControl variant="outlined" size="small">
                <UISelect name="actionSelector" defaultValue={defaultAction?.value} onChange={handleActionChange}>
                  {actionList.reduce((result: any[], a) => {
                    if (a.enabled && a.value) {
                      result.push(
                        <UIMenuItem key={a.id} value={a.value}>
                          {a.value}
                        </UIMenuItem>,
                      );
                      return result;
                    }
                    return result;
                  }, [])}
                </UISelect>
              </UIFormControl>
              {selectedActionId === "set-limit" && (
                <UITextField
                  name="billLimit"
                  placeholder="Value"
                  value={billLimitValue}
                  InputProps={{
                    startAdornment: <UIInputAdornment position="start">£</UIInputAdornment>,
                  }}
                  onChange={(e) => {
                    const val = to2Digits(e.target.value);
                    setNewBillLimit(val);
                    validateSingle(e.target.name, val);
                  }}
                  variant="outlined"
                  size="small"
                  error={Boolean(validationErrors.billLimit)}
                  helperText={validationErrors.billLimit}
                />
              )}
              <UIButton
                variant="contained"
                color="primary"
                size="medium"
                onClick={handleSubmit}
                className={classes.submit}
                disabled={!selectedAction?.canSubmit()}
                data-cy="submit-btn"
              >
                Submit
              </UIButton>
            </UICard>
            <UIConfirm
              open={openDialog}
              setOpen={setOpenDialog}
              onConfirm={selectedAction?.action}
              title={selectedAction?.dialogTitle}
              onSubmitTitle="Confirm"
              width="sm"
            >
              {selectedAction?.dialogText}
            </UIConfirm>
          </UIGrid>
          {isLoading ? (
            <div className={classes.loader}>
              <UILoader />
            </div>
          ) : (
            <div data-cy="mobile-selection-table">
              <UIDataTable
                title=""
                data={parsed}
                columns={["CLI/Identifier", "", "Tag", "Current Limit", "Next Month"]}
                options={{
                  page,
                  rowsPerPage: rowCount,
                  rowsPerPageOptions: rowCountOptions,
                  onTableChange: handleTableChange,
                  count: assetsResp?.totalElements,
                  pagination: true,
                  download: false,
                  elevation: 1,
                  print: false,
                  responsive: "standard",
                  selectableRows: "none",
                  serverSide: true,
                  selectToolbarPlacement: "none",
                  filter: false,
                  viewColumns: false,
                  sort: false,
                  search: false,
                  rowHover: true,
                  setTableProps: () => ({ size: "medium" }),
                  setRowProps: (row) => ({ "data-cy": `row-id-${row?.[0]}` }),
                }}
              />
            </div>
          )}
        </UIGrid>
      )}
    </Main>
  );
};

const useStyles = createStylesheet((theme) => ({
  loader: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "calc(50vh - 200px)",
  },
  actionsCtr: {
    padding: "10px",
    marginTop: "15px",
    marginBottom: "10px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: theme.spacing(1.5),
    width: "100%",
  },
  alert: {
    width: "100%",
    marginBottom: "5px",
    "& img": {
      objectFit: "contain",
      width: theme.spacing(2),
      margin: "0px 4px -2px",
    },
    [theme.breakpoints.down("md")]: {
      "& .MuiAlert-message": {
        fontSize: "0.7rem",
      },
      "& img": {
        width: "13px",
      },
    },
  },
  actionItem: {
    width: "100%",
  },
  submit: {
    marginTop: 2,
  },
}));

export default BillLimit;
