import { cloneElement, useEffect, useState } from "react";
import { UIGrid } from "../../_app/components";
import Main from "../../_app/layouts/Main";
import { useAccountContext } from "../../_app/providers/AccountHierarchyProvider";
import { createStylesheet } from "../../_app/utils/styles";
import { useHasAnyFeatureEnabled } from "../../feature/hooks";
import { Feature } from "../../feature/types";
import { permissionCodes, useHasPermission } from "../../permission/hooks";
import { useHeadUserLevel } from "../../user-level/hooks";
import Advertisement from "../widgets/Advertisement";
import BalanceOverdue from "../widgets/BalanceOverdue";
import Charges from "../widgets/Charges";
import LatestBill from "../widgets/LatestBill";
import AssetsSearch from "../widgets/nav/AssetsSearch";
import HelpLink from "../widgets/nav/HelpLink";
import ManageMobilesLink from "../widgets/nav/ManageMobilesLink";
import UsageAlertsLink from "../widgets/nav/UsageAlertsLink";
import Welcome from "../widgets/Welcome";
import { UserType } from "../../user-level/types";

export const Home = () => {
  const classes = useStyles();
  const { currentAccountLevel } = useAccountContext();
  const [viewAsLevel, setViewAsLevel] = useState(currentAccountLevel?.id);
  const contextLevelId = currentAccountLevel?.id;

  // Feature Check
  const hasStatementsFeature = useHasAnyFeatureEnabled(Feature.Statements);
  const hasSearchAssetsFeature = useHasAnyFeatureEnabled(Feature.AssetSearch);
  const hasMobileManagementFeature = useHasAnyFeatureEnabled(
    Feature.AutoSwitchPacStac,
    Feature.Bars,
    Feature.BillLimit,
    Feature.SimLock,
    Feature.SimOrder,
    Feature.SimSwap,
    Feature.SimUnlock,
    Feature.WorldwideDataRoaming,
  );
  const hasUsageAlertsFeature = useHasAnyFeatureEnabled(Feature.UsageAlerts);
  const hasHelpFeature = useHasAnyFeatureEnabled(Feature.DclSupport);

  // Permission Check
  const hasBillingManagePermission = Boolean(useHasPermission(permissionCodes.BILLING_MANAGE));
  const hasBillingStatementPermission = useHasPermission(permissionCodes.BILLING_STATEMENT);
  const hasMobileManagementPermission = useHasPermission(permissionCodes.MOBILE_MANAGEMENT);
  const hasAssetsPermission = useHasPermission(permissionCodes.VIEW_ASSETS);
  const hasUsageAlertsPermission = useHasPermission(permissionCodes.ALERTS_ACCESS_FEATURE);
  const hasHelpPermission = useHasPermission(permissionCodes.SUPPORT_ACCESS);

  useEffect(() => {
    setViewAsLevel(contextLevelId);
  }, [contextLevelId]);

  const headLevel = useHeadUserLevel();
  const accountUser = headLevel && viewAsLevel && viewAsLevel === headLevel?.id;
  const subAccountUser = headLevel && viewAsLevel && viewAsLevel > headLevel?.id;

  const hasWidget = (id: string) => {
    switch (id) {
      case "welcome":
        return true;
      case "advertisement":
        return true;
      case "latest-bill":
        return hasBillingManagePermission && accountUser;
      case "charges":
        return hasBillingManagePermission && (accountUser || subAccountUser);
      case "balance-overdue":
        return hasStatementsFeature && hasBillingStatementPermission && accountUser;
      case "mobile-management":
        return hasMobileManagementFeature && hasMobileManagementPermission;
      case "assets":
        return hasSearchAssetsFeature && hasAssetsPermission;
      case "usage-alerts":
        return hasUsageAlertsFeature && hasUsageAlertsPermission;
      case "help":
        return hasHelpFeature && hasHelpPermission;
      default:
        return false;
    }
  };

  const renderResponsiveBlock =
    (id: string, gridPoints: number = 12, oneProps: any = {}) =>
    (...args: any) => {
      const widgets = args.filter(Boolean);
      return widgets.map((widget: any, i: number) => {
        const equal = gridPoints / widgets.length;
        const isOne = widgets.length === 1;
        const customProps = isOne && oneProps ? oneProps : {};
        const md = equal % 1 !== 0 || equal;
        const Widget = cloneElement(widget, {
          key: `${id}-${i}`,
          md,
          ...customProps,
        });
        return Widget;
      });
    };

  return (
    <Main data-cy="home-page" allowedUserTypes={[UserType.ACCOUNT, UserType.COST_CENTRE]}>
      <UIGrid container spacing={2} className={classes.ctr}>
        <>
          {hasWidget("welcome") && <Welcome />}
          {hasWidget("advertisement") && <Advertisement />}
        </>
        <>
          {hasWidget("latest-bill") && <LatestBill userType={subAccountUser ? "SUB_ACCOUNT" : undefined} />}
          {renderResponsiveBlock("balance", 6, { align: "left" })(
            hasWidget("charges") && <Charges />,
            hasWidget("balance-overdue") && <BalanceOverdue />,
          )}
        </>
        <>
          {renderResponsiveBlock("links")(
            hasWidget("mobile-management") && <ManageMobilesLink />,
            hasWidget("assets") && <AssetsSearch />,
            hasWidget("usage-alerts") && <UsageAlertsLink />,
            hasWidget("help") && <HelpLink />,
          )}
        </>
      </UIGrid>
    </Main>
  );
};

const useStyles = createStylesheet((theme) => ({
  ctr: {
    minWidth: "100%",
  },
}));

export default Home;
