import { Bar } from "react-chartjs-2";
import { formatNumber } from "../../../_app/utils/format";
import { createStylesheet } from "../../../_app/utils/styles";
import { Query } from "../../../filter/types";
import { useCallDuration, useVerticalBarChartOptions } from "../../hooks";
import GraphWidget from "./GraphWidget";

interface Props {
  queries: Query[];
}

const CallDuration = ({ queries }: Props) => {
  const classes = useStyles();
  const { data = [], isFetching, isError } = useCallDuration(queries);
  const hasError = isError || data?.datasets?.length <= 0;
  const options = useVerticalBarChartOptions(
    undefined,
    (value: any) => formatNumber(value / 60, 0),
    (context: any) => {
      if (context.parsed.y !== null) {
        return formatNumber(context.parsed.y / 60, 0);
      }
      return "";
    },
  );

  return (
    <GraphWidget title="Call Duration (minutes)" isLoading={isFetching} hasError={hasError}>
      <div className={classes.horizontalBarWrap}>
        <Bar data={data} options={options} />
      </div>
    </GraphWidget>
  );
};

const useStyles = createStylesheet(() => ({
  horizontalBarWrap: {
    position: "relative",
    minHeight: 410,
    width: "100%",
  },
}));

export default CallDuration;
