import { addMinutes } from "date-fns";
import { User, UserProfile } from "oidc-client-ts";
import { useCallback, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { AjaxResponse } from "../_app/api";
import { useFeedbackAlerts, useQueryParam } from "../_app/hooks";
import { decodeJwt } from "../_app/utils";
import { checkMFAStatus, getIdentityUser } from "./api";
import { logout, updateUser } from "./sso-api";
import { DecodedJWT, IdentityUser, SignedInUser } from "./types";
import { isCognitoUser } from "./utils";
import { ACCOUNT_CONTEXT } from "../context/utils";

export function useIdentityUser(options?: any) {
  return useQuery<SignedInUser | undefined, AjaxResponse>(["identityUser"], getIdentityUser, {
    cacheTime: 0,
    ...options,
  });
}

export function useJWTAuth() {
  const { setFeedbackAlertError } = useFeedbackAlerts();
  const [isLoading, setIsLoading] = useState(true);
  const jwtParam = useQueryParam("jwt");
  const path = useQueryParam("path");

  const handleSuccess = useCallback(() => {
    setIsLoading(false);
    window.location.replace(`${window.location.origin}${path ? `/${path}` : ""}`);
  }, [path]);

  const setInSession = useCallback(
    (jwtEncoded: string) => {
      try {
        if (isCognitoUser()) {
          impersonateAmplifyUser(jwtEncoded);
        } else {
          impersonateSSOUser(jwtEncoded);
        }
        handleSuccess();
      } catch (e) {
        setFeedbackAlertError(e);
      }
    },
    [handleSuccess, setFeedbackAlertError],
  );

  useEffect(() => {
    if (typeof jwtParam === "string") {
      setInSession(jwtParam);
    }
  }, [jwtParam, setInSession]);

  return { isLoading };
}

function impersonateSSOUser(jwtEncoded: string) {
  const sessionLengthMins = 20;
  const jwt: DecodedJWT = decodeJwt(jwtEncoded);

  if (!jwt) throw new Error("Invalid token.");
  const user = {
    profile: {
      ...jwt,
    } as Partial<UserProfile>,
    access_token: jwtEncoded,
    token_type: "Bearer",
    scope: "openid profile roles email HUB_FE_API",
    expires_at: Math.trunc(addMinutes(new Date(), sessionLengthMins).getTime() / 1000),
  } as IdentityUser;
  const updatedUser = new User(user);
  updateUser(updatedUser)
    .then()
    .catch((err) => {
      throw new Error("Impersonation failed.");
    });
}

async function impersonateAmplifyUser(idJwtToken: string) {
  const keyPrefix = `CognitoIdentityServiceProvider.${window.authConfig?.customerPortalClientId}`;
  localStorage.setItem(`${keyPrefix}.LastAuthUser`, "impersonated_user");
  localStorage.setItem(`${keyPrefix}.impersonated_user.idToken`, idJwtToken);
  localStorage.setItem(`${keyPrefix}.impersonated_user.accessToken`, idJwtToken);
  localStorage.setItem(`${keyPrefix}.impersonated_user.refreshToken`, "");
}

export function useJWTLogout() {
  if (isCognitoUser()) {
    amplifyLogout();
  } else {
    jwtSSOLogout();
  }
}

function jwtSSOLogout() {
  localStorage.removeItem(ACCOUNT_CONTEXT);
  logout();
}

function amplifyLogout() {
  const keyPrefix = `CognitoIdentityServiceProvider.${window.authConfig?.customerPortalClientId}`;

  localStorage.removeItem(`${keyPrefix}.LastAuthUser`);
  localStorage.removeItem(`${keyPrefix}.manual_user.idToken`);
  localStorage.removeItem(ACCOUNT_CONTEXT);
  window.location.replace(window.location.origin);
}

export function useMfaEnabled(accessToken: string, options = {}) {
  return useQuery<boolean, AjaxResponse>(["mfa-enabled"], () => checkMFAStatus(accessToken), {
    ...options,
  });
}
