import api from "../_app/api";
import { CreateTicket, GetTicketCommentsParams, GetTicketCommentsResponse, GetTicketsResponse, Ticket } from "./types";

export const getTickets = async (page: number, limit: number) => {
  let { data }: { data: GetTicketsResponse } = await api({
    method: "GET",
    url: "/support",
    context_injection: true,

    params: {
      page,
      size: limit,
    },
  });
  return data;
};

export const getTicketById = async (id: string) => {
  const { data }: { data: Ticket } = await api({
    method: "GET",
    context_injection: true,

    url: `/support/${id}`,
  });
  return data;
};

export const createTicket = async (ticket: CreateTicket) => {
  let { data }: { data: Ticket } = await api({
    method: "POST",
    url: "/support",
    context_injection: true,

    data: ticket,
  });
  return data;
};

export const getTicketComments = async (params: GetTicketCommentsParams) => {
  const { ticketId, ...parsed } = params;
  const { data }: { data: GetTicketCommentsResponse } = await api({
    method: "GET",
    context_injection: true,

    url: `/support/${ticketId}/comments`,
    params: parsed,
  });
  return data;
};

export const createTicketComment = async (ticketId: any, comment: string) => {
  const { data }: { data: any } = await api({
    method: "POST",
    context_injection: true,

    url: `/support/${ticketId}/comments`,
    data: { comment },
  });
  return data;
};
