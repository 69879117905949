import { AxiosResponse } from "axios";
import { useCallback, useMemo, useState } from "react";
import { UIAlert, UIButton, UIGrid, UIStack } from "../../_app/components";
import { CloudDownload as DownloadIcon } from "../../_app/components/icons";
import { rowParser, SortRequestParam } from "../../_app/components/Table/helpers";
import SortableHeaderLabel from "../../_app/components/Table/SortableHeaderLabel";
import UIDataTable, {
  UICustomHeadLabelRenderOptions,
  UIDataTableColumnDef,
  UIDataTableColumnOptions,
  UIDataTableState,
} from "../../_app/components/Table/UIDataTable";
import UILoader from "../../_app/components/UILoader";
import Main from "../../_app/layouts/Main";
import { downloadFile, extractFilenameFromHeaders } from "../../_app/utils";
import { capitalize, formatDate, parseStringToDate } from "../../_app/utils/format";
import { createStylesheet } from "../../_app/utils/styles";
import { Feature } from "../../feature/types";
import FiltersCard from "../../filter/components/FiltersCard";
import { useFilterQueries } from "../../filter/hooks";
import { Query } from "../../filter/types";
import { permissionCodes } from "../../permission/hooks";
import DownloadButton from "../components/DownloadButton";
import { useCommunicationFilters, useCommunications, useExportAllCommunications } from "../hooks";
import { CommunicationDetail } from "../types";

const rowCountOptions = [10, 25, 50];
const COMMUNICATIONS_FILTERS: string = "COMMUNICATIONS";

const formatQueries = (queries: Query[]) => {
  return queries.map((query) => {
    if (query.id === "creationDate") {
      return {
        ...query,
        value: formatDate(parseStringToDate(query.value, "dd/MM/yyyy"), "yyyy-MM-dd"),
      };
    }
    return query;
  });
};

export const Communications = () => {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState<SortRequestParam>({ column: "creationDate", direction: "desc" });
  const queries = useFilterQueries();
  const [rowCount, setRowCount] = useState(rowCountOptions[0]);
  const { data, isFetching } = useCommunications(page, rowCount, sort, formatQueries(queries));
  const { isFetching: isFileFetching, refetch } = useExportAllCommunications({
    onSuccess: (response: AxiosResponse) => {
      downloadFile(response?.data, extractFilenameFromHeaders(response) ?? "communication-export.csv");
    },
  });

  const handleTableChange = (action: string, state: UIDataTableState) => {
    switch (action) {
      case "changePage":
        setPage(state.page);
        break;
      case "changeRowsPerPage":
        setRowCount(state.rowsPerPage);
        break;
      default:
        break;
    }
  };

  const handleSortChange = (column: string, direction: "desc" | "asc") => {
    setSort({ column: column, direction: direction });
  };

  const customHeader = useCallback(
    (options: UICustomHeadLabelRenderOptions) => (
      <SortableHeaderLabel label={options.label ?? options.name} name={options.name} sort={sort} />
    ),
    [sort],
  );

  const sortOptions: UIDataTableColumnOptions = {
    sortDescFirst: false,
    customHeadLabelRender: (options: UICustomHeadLabelRenderOptions) => customHeader(options),
  };

  const tableColumns: UIDataTableColumnDef[] = [
    { name: "creationDate", label: "Date", options: sortOptions },
    { name: "type", label: "Type", options: sortOptions },
    { name: "subject", label: "Subject", options: sortOptions },
    { name: "recipient", label: "Recipient", options: sortOptions },
    { name: "associatedProduct", label: "Associated Product", options: sortOptions },
    { name: "" },
  ];

  const parsed = useMemo(
    () =>
      data?.content.map((item: CommunicationDetail) => {
        return rowParser([
          item?.date,
          capitalize(item?.type),
          item?.subject,
          item?.recipient,
          item?.associatedProduct,
          <DownloadButton key={item?.communicationId} communicationId={item?.communicationId} filename={item?.filename} />,
        ]);
      }) || [],
    [data],
  );

  return (
    <Main
      title={
        <>
          Communications
          <UIStack direction={"row"} spacing={1}>
            <UIButton
              variant="text"
              color="primary"
              data-cy="export-communications-button"
              onClick={() => refetch()}
              startIcon={isFileFetching ? <UILoader size={20} /> : <DownloadIcon />}
              disabled={isFileFetching}
            >
              Export all to csv
            </UIButton>
          </UIStack>
        </>
      }
      data-cy="communications-page"
      accessPermission={[permissionCodes.COMMUNICATIONS]}
      feature={Feature.Communications}
      needSelectedAccount={false}
      isLoading={false}
    >
      <UIGrid size={{ xs: 12, md: 12 }}>
        <UIAlert severity="info" className={classes.info}>
          Find relevant communications we've sent to you here. Easily access and review important information anytime, ensuring
          you stay organised and never miss an update.
        </UIAlert>
        <UIGrid>
          <FiltersCard fetchHook={useCommunicationFilters} hasReset={true} usage={COMMUNICATIONS_FILTERS} />
        </UIGrid>
        {isFetching ? (
          <div className={classes.loader}>
            <UILoader />
          </div>
        ) : (
          <div className={classes.ctr} data-cy="communications-table">
            <UIDataTable
              title=""
              data={parsed}
              columns={tableColumns}
              options={{
                onTableChange: handleTableChange,
                onColumnSortChange: handleSortChange,
                pagination: true,
                page: page,
                rowsPerPage: rowCount,
                rowsPerPageOptions: rowCountOptions,
                count: data?.totalElements,
                download: false,
                elevation: 1,
                print: false,
                responsive: "standard",
                selectToolbarPlacement: "none",
                filter: false,
                serverSide: true,
                viewColumns: false,
                sort: true,
                sortOrder: { name: sort.column, direction: sort.direction },
                search: false,
                selectableRows: "none",
                rowHover: true,
                setRowProps: (row) => ({
                  "data-cy": `row-id-${row?.[0]}`,
                }),
                setTableProps: () => ({ size: "small" }),
              }}
            />
          </div>
        )}
      </UIGrid>
    </Main>
  );
};

const useStyles = createStylesheet((theme) => ({
  ctr: {
    width: "100%",
    padding: "0",
    marginTop: theme.spacing(1.5),
    "& .Mui-active .MuiTableSortLabel-icon": {
      color: theme.palette.primary.main,
    },
  },
  info: {
    width: "fit-content",
    marginBottom: theme.spacing(3),
    alignItems: "center",
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    flex: 1,
  },
}));

export default Communications;
