import { useQuery } from "react-query";
import { AjaxResponse } from "../_app/api";
import { useIdentityUser } from "../auth/hooks";
import { getEnabledFeatures } from "./api";
import { Feature } from "./types";

export function useEnabledFeatures(options = {}) {
  const { data: identityUser } = useIdentityUser();

  return useQuery<Feature[], AjaxResponse>("enabledFeatures", () => getEnabledFeatures(), {
    staleTime: 1000 * 60 * 15,
    enabled: Boolean(identityUser?.profile?.sub),
    ...options,
  });
}

export function useHasAnyFeatureEnabled(...features: Feature[]): boolean {
  const { data: enabledFeatures } = useEnabledFeatures();

  if (!enabledFeatures || !features) return false;

  if (features.length === 0) return true;

  return features.some((feature) => enabledFeatures.includes(feature));
}
