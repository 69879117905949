import {
  activateSim,
  cancelAutoSwitch,
  cancelAutoSwitchCostCentre,
  getAllAssetsForAutoSwitchCancel,
  getAllSimForActivation,
  getAutoSwitchResults,
  getAutoSwitchResultsCostCentre,
  getSimDetails,
  getSimDetailsCostCentre,
  performSimSwap,
  performSimSwapCostCentre,
  requestAutoSwitch,
  requestAutoSwitchCostCentre,
} from "./api";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { AjaxResponse } from "../_app/api";
import {
  AutoSwitchResult,
  DisconnectedAsset,
  DispatchedAsset,
  GetDisconnectionAssetsResponse,
  GetDispatchedAssetsResponse,
  RequestAutoSwitchParams,
  SimDetails,
  SimSwapOperation,
} from "./types";
import { useFeedbackAlerts } from "../_app/hooks";
import { useAccountContext } from "../_app/providers/AccountHierarchyProvider";
import { UserType } from "../user-level/types";
import { Page } from "../_app/api/types";

export function useSimDetails(simNumber: string, network: string, options = {}) {
  const { userType, contextId } = useAccountContext();

  const callback =
    userType === UserType.COST_CENTRE
      ? () => getSimDetailsCostCentre(contextId, simNumber, network)
      : () => getSimDetails(contextId, simNumber, network);

  return useQuery<SimDetails, AjaxResponse>(["simDetails", simNumber, network], callback, {
    ...options,
  });
}

export function useRequestAutoSwitch(options = {}) {
  const { contextId, userType } = useAccountContext();

  const callback = userType === UserType.COST_CENTRE ? requestAutoSwitchCostCentre : requestAutoSwitch;

  return useMutation<string, AjaxResponse, RequestAutoSwitchParams>((params) => callback(contextId, params), {
    ...options,
  });
}

export function useCancelAutoSwitch(options = {}) {
  const { setFeedbackAlertError, setFeedbackAlertSuccess } = useFeedbackAlerts();
  const { contextId, userType } = useAccountContext();

  const queryClient = useQueryClient();
  return useMutation<RequestAutoSwitchParams, AjaxResponse, any>(
    (assetId: any) =>
      userType === UserType.COST_CENTRE ? cancelAutoSwitchCostCentre(contextId, assetId) : cancelAutoSwitch(assetId),
    {
      onSuccess: (data, assetId) => {
        queryClient.setQueriesData(["disconnectSimNumbers"], (old: any) => {
          if (old?.list?.length) {
            old.list = old.list.map((item: DisconnectedAsset) => {
              return {
                ...item,
                requestStatus: item.id === assetId ? "PENDING" : item.requestStatus,
              };
            });
          }
          return old;
        });

        setFeedbackAlertSuccess("Your request has been submitted - this may take a few seconds for it to be applied.");
      },
      onError: (error) => {
        setFeedbackAlertError(error?.data?.message);
      },
      ...options,
    },
  );
}

export function useAutoSwitchResults(requestId: string, page: number, limit: number, options = {}) {
  const { contextId, userType } = useAccountContext();

  return useQuery<any, AjaxResponse, Page<AutoSwitchResult>>(
    ["autoSwitchResults", { requestId, page, limit }],
    () => {
      if (userType === UserType.COST_CENTRE) {
        return getAutoSwitchResultsCostCentre(contextId, requestId, page, limit);
      } else {
        return getAutoSwitchResults(requestId, page, limit);
      }
    },
    { ...options },
  );
}

export function useAllSimForActivation(page: number, limit: number, options = {}) {
  const { setFeedbackAlertError } = useFeedbackAlerts();

  return useQuery<GetDispatchedAssetsResponse, AjaxResponse>(
    ["dispatchSimNumbers", { page, limit }],
    () => getAllSimForActivation(page, limit),
    {
      ...options,
      onError: (error) => {
        setFeedbackAlertError(error?.data?.message);
      },
    },
  );
}

export function useAllAssetsForAutoSwitchCancel(page: number, limit: number, options = {}) {
  const { setFeedbackAlertError } = useFeedbackAlerts();

  return useQuery<GetDisconnectionAssetsResponse, AjaxResponse>(
    ["disconnectSimNumbers", { page, limit }],
    () => getAllAssetsForAutoSwitchCancel(page, limit),
    {
      ...options,
      onError: (error) => {
        setFeedbackAlertError(error?.data?.message);
      },
    },
  );
}

export function useSimActivate(options = {}) {
  const queryClient = useQueryClient();
  const { setFeedbackAlertError, setFeedbackAlertSuccess } = useFeedbackAlerts();

  return useMutation<any, AjaxResponse, any>((asset: DispatchedAsset) => activateSim(asset.id, asset.newSimNumber), {
    ...options,
    onSuccess: () => {
      queryClient.invalidateQueries(["dispatchSimNumbers"]);
      setFeedbackAlertSuccess("Activation request submitted.");
    },
    onError: (error) => {
      setFeedbackAlertError(error?.data?.message);
    },
  });
}

export function useSimSwapUpdate(options: any = {}) {
  const queryClient = useQueryClient();
  const { setFeedbackAlertError, setFeedbackAlertSuccess } = useFeedbackAlerts();
  const { userType, contextId } = useAccountContext();

  const callback = userType === UserType.COST_CENTRE ? performSimSwapCostCentre : performSimSwap;

  return useMutation<any, AjaxResponse, SimSwapOperation[]>((params) => callback(contextId, params), {
    ...options,
    onSuccess: () => {
      queryClient.invalidateQueries(["assets"]);
      setFeedbackAlertSuccess("Your request has been submitted.");
      options?.onSuccess?.();
    },
    onError: (error) => {
      setFeedbackAlertError(error?.data?.message);
    },
  });
}
