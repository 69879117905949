import { type JSX, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Main from "../../_app/layouts/Main";
import { permissionCodes } from "../../permission/hooks";
import {
  UIAlert,
  UIButton,
  UICard,
  UICheckbox,
  UIConfirm,
  UIGrid,
  UILoader,
  UISkeleton,
  UITypography,
} from "../../_app/components";
import { useHasAnyFeatureEnabled } from "../../feature/hooks";
import { useFeedbackAlerts } from "../../_app/hooks";
import { useBarsUpdate, useSingleBar } from "../../bars/hooks";
import { genericError } from "../../_app/utils/text";
import { createStylesheet } from "../../_app/utils/styles";
import { Feature } from "../../feature/types";
import { UserType } from "../../user-level/types";

export const SimLock = () => {
  const classes = useStyles();
  const { setFeedbackAlertError } = useFeedbackAlerts();
  const navigate = useNavigate();
  const location = useLocation();
  const asset = location.state?.asset;
  const [orderNew, setOrderNew] = useState<boolean>(false);
  const { data: stolenBar, isFetching, error } = useSingleBar("stolen", asset?.id || "");
  const [stolenConfirm, setStolenConfirm] = useState<boolean>(false);
  const [stolen, setStolen] = useState<boolean>(false);
  const canOrder = useHasAnyFeatureEnabled(Feature.SimOrder) && stolen;

  const { mutate: executeBarsUpdate, data: successMessage, error: updateError, isLoading: isUpdating } = useBarsUpdate();

  const showStolenLoader = isFetching || isUpdating;

  const switchStolenBar = () => {
    executeBarsUpdate({
      assetIds: [asset?.id],
      operations: [
        {
          name: stolenBar?.name,
          network: asset?.network,
          value: !stolen,
        },
      ],
    });
  };

  const tableRows = (items: { title: string; val: string | JSX.Element }[]) => {
    return items?.map(({ title, val }) => {
      return (
        <UIGrid container key={title} className={classes.row}>
          <UIGrid size={{ sm: 3 }}>
            <UITypography variant="h4" data-cy="sim-lock" component="div">
              {title}
            </UITypography>
          </UIGrid>
          <UIGrid>
            <UITypography data-cy="sim-lock-data" component="div">
              {val ?? <UISkeleton width={150} />}
            </UITypography>
          </UIGrid>
        </UIGrid>
      );
    });
  };

  useEffect(() => {
    if (stolenBar) setStolen(Boolean(stolenBar?.value));
  }, [stolenBar]);

  useEffect(() => {
    if (successMessage) setStolen((oldStolen) => !oldStolen);
  }, [successMessage]);

  useEffect(() => {
    if (!asset || updateError) {
      setFeedbackAlertError("No mobile number selected");
      navigate("/mobile-management");
    }
  }, [asset, updateError, navigate, setFeedbackAlertError]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (error) {
      setFeedbackAlertError(error?.data?.message || genericError());
      timer = setTimeout(() => {
        navigate(-1);
      }, 1000);
    }
    return () => clearTimeout(timer);
  }, [error, navigate, setFeedbackAlertError]);

  return (
    <Main
      title="Lost / Stolen Phone"
      data-cy="sim-lock-page"
      feature={Feature.SimLock}
      accessPermission={permissionCodes.MOBILE_MANAGEMENT}
      isLoading={false}
      showNav={false}
      showToolbar={false}
      showFooter={false}
      allowedUserTypes={[UserType.COST_CENTRE, UserType.ACCOUNT]}
    >
      <div className={classes.ctr}>
        <UICard hasBorder padding="16px">
          {tableRows([
            { title: "CLI/Identifier", val: asset?.cli },
            { title: "Account", val: asset?.accountCode },
          ])}
        </UICard>
        <UICard isFetching={orderNew} fetchingText={"Redirecting to Order New SIM"} hasBorder padding="16px">
          <UIAlert severity="warning" className={classes.info}>
            Please note: this will only apply a bar to the SIM, the handset will not be affected.
          </UIAlert>
          <UIGrid container className={classes.row}>
            <UIGrid>
              <UITypography variant="h4" data-cy="sim-lock" component="div" align="center" className={classes.checkbox}>
                {showStolenLoader ? (
                  <UILoader size={20} />
                ) : (
                  <UICheckbox
                    disabled={showStolenLoader}
                    checked={stolen}
                    onChange={() => setStolenConfirm(true)}
                    color="primary"
                  />
                )}
              </UITypography>
            </UIGrid>
            <UIGrid>
              <UITypography component="div">Apply Stolen bar to SIM</UITypography>
            </UIGrid>
          </UIGrid>
        </UICard>
        <div>
          <UIButton
            onClick={() => {
              setOrderNew(true);
              return setTimeout(() => {
                navigate("/mobile-management/sim-order", {
                  state: {
                    ...location.state,
                    from: "Lost/Stolen Phone",
                  },
                });
              }, 2000);
            }}
            disabled={!canOrder}
            variant="contained"
            color="primary"
          >
            Order New SIM
          </UIButton>
        </div>
      </div>
      <UIConfirm
        title={`Are you sure you want to ${stolen ? "remove" : "apply"} the stolen bar?`}
        setOpen={setStolenConfirm}
        open={stolenConfirm}
        onConfirm={switchStolenBar}
      >
        Changes may take a few hours to take effect.
      </UIConfirm>
    </Main>
  );
};

const useStyles = createStylesheet((theme) => ({
  ctr: {
    display: "flex",
    flexDirection: "column",
    gap: "15px 30px",
    height: "100%",
  },
  row: {
    margin: "10px 0",
    gap: theme.spacing(2),
    alignItems: "center",
    minWidth: 550,
  },
  checkbox: {
    height: "42px",
    width: "42px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  info: {
    alignItems: "center",
  },
}));

export default SimLock;
