import { type JSX, ReactElement, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { login } from "../sso-api";
import { useIdentityUser } from "../hooks";
import { useHubUserExist } from "../../user/hooks";
import LoadingOverlay from "../../_app/components/LoadingOverlay";
import { isCognitoUser } from "../utils";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useAccountContext } from "../../_app/providers/AccountHierarchyProvider";
import { useMyAccounts } from "../../account/hooks";
import { UserType } from "../../user-level/types";

interface PrivateRouteProps {
  children: ReactElement<any, any> | null;
}

export const PrivateRoute = ({ children }: PrivateRouteProps): JSX.Element => {
  const { isReady, userType, contextId } = useAccountContext();
  const { data: groupList } = useMyAccounts({ enabled: userType === UserType.ACCOUNT });
  const navigate = useNavigate();

  useEffect(() => {
    if (groupList && groupList?.length > 1 && !contextId) {
      navigate("/groups");
    }
  }, [groupList, contextId, navigate]);

  if (!isReady) {
    return <LoadingOverlay />;
  }

  return <PrivateRouteContent>{children}</PrivateRouteContent>;
};

const PrivateRouteContent = ({ children }: PrivateRouteProps): JSX.Element => {
  const navigate = useNavigate();
  const { authStatus } = useAuthenticator((context: any) => [context.authStatus]);
  const { data: identityUser, isFetching: identityUserFetching } = useIdentityUser();
  const hasValidIdentityUser = identityUser && !identityUser?.expired;
  const { data: doesHubUserExist, isFetching: hubUserExistenceFetching } = useHubUserExist();

  useEffect(() => {
    if (!identityUserFetching && !hasValidIdentityUser) {
      if (isCognitoUser()) {
        navigate("/login");
      } else {
        login();
      }
    }
  }, [hasValidIdentityUser, identityUserFetching, navigate]);

  useEffect(() => {
    if (hasValidIdentityUser && !hubUserExistenceFetching && !doesHubUserExist) {
      setTimeout(() => navigate("/no-user", { replace: true }));
    }
  }, [hasValidIdentityUser, hubUserExistenceFetching, doesHubUserExist, navigate]);

  if (isCognitoUser() && authStatus !== "authenticated" && hasValidIdentityUser) {
    return <Navigate to="/login" />;
  }

  if (doesHubUserExist) {
    return children || <div />;
  }

  return <LoadingOverlay />;
};
