import { useQuery } from "react-query";
import { AjaxResponse } from "../_app/api";
import { useIdentityUser } from "../auth/hooks";
import { getEnabledDevelopmentFeatures } from "./api";
import { DevelopmentFeature } from "./types";

export function useEnabledDevelopmentFeatures(options = {}) {
  const { data: identityUser } = useIdentityUser();

  return useQuery<DevelopmentFeature[], AjaxResponse>("enabledDevelopmentFeatures", () => getEnabledDevelopmentFeatures(), {
    staleTime: 1000 * 60 * 15,
    enabled: Boolean(identityUser?.profile?.sub),
    ...options,
  });
}

export function useHasAnyDevelopmentFeatureEnabled(...developmentFeatures: DevelopmentFeature[]): boolean {
  const { data: enabledDevelopmentFeatures } = useEnabledDevelopmentFeatures();

  if (!enabledDevelopmentFeatures || !developmentFeatures) return false;

  if (developmentFeatures.length === 0) return true;

  return developmentFeatures.some((feature) => enabledDevelopmentFeatures.includes(feature));
}
