import { ChangeEvent, useState } from "react";
import { UIButton, UICard, UIGrid, UITextField, UITypography } from "../../../_app/components";
import { createStylesheet } from "../../../_app/utils/styles";
import { useHasAnyFeatureEnabled } from "../../../feature/hooks";
import { Feature } from "../../../feature/types";
import SelectDropdownLegacy from "../../../form/components/SelectDropdownLegacy";
import { useValidation } from "../../../form/hooks";
import { BillingAddress as Address, BillingAddressUpdateParams } from "../../types";

export interface Props {
  handleUpdate: (data: BillingAddressUpdateParams) => void;
  address: any;
}

export default function BillingAddress({ handleUpdate = () => null, address }: Props) {
  const classes = useStyles();
  const canEdit = useHasAnyFeatureEnabled(Feature.BillingAddressUpdate);

  const [requestUpdateBody, setRequestUpdateBody] = useState<Address>(address);

  const validationConfig = {
    addressLine1: { required: true },
    addressLine2: { required: false },
    addressLine3: { required: false },
    addressLine4: { required: false },
    addressLine5: { required: false },
    country: { required: true },
    postCode: { required: true },
  };

  const { validationErrors, validateSingle, validateAll } = useValidation(validationConfig, requestUpdateBody);

  const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
    const key = event.target.name;
    const val = event.target.value;
    setRequestUpdateBody({ ...requestUpdateBody, [key]: val });
    validateSingle(key, val);
  };

  const [countryList] = useState(
    requestUpdateBody?.countries?.map((country: string, index: number) => {
      return { id: index, label: country, value: country };
    }),
  );

  const onSubmit = () => {
    const errors = validateAll();
    if (errors.length <= 0) {
      const { countries, ...params } = requestUpdateBody;
      handleUpdate(params);
    }
  };

  const isSameAsOrigin = (): boolean => {
    return (
      address?.addressLine1 === requestUpdateBody?.addressLine1 &&
      address?.addressLine2 === requestUpdateBody?.addressLine2 &&
      address?.addressLine3 === requestUpdateBody?.addressLine3 &&
      address?.addressLine4 === requestUpdateBody?.addressLine4 &&
      address?.addressLine5 === requestUpdateBody?.addressLine5 &&
      address?.postCode === requestUpdateBody?.postCode &&
      address?.country === requestUpdateBody?.country
    );
  };

  const inputProps = {
    readOnly: !canEdit,
  };

  return (
    <UIGrid size={{ xs: 12, md: 6 }} data-cy="account-details-form">
      <UICard>
        <UITypography variant="h2" gutterBottom>
          Billing Address
        </UITypography>
        <UITextField
          className={classes.textField}
          name="addressLine1"
          label="Address line 1"
          type="text"
          defaultValue={address?.addressLine1}
          variant="outlined"
          size="small"
          fullWidth
          error={Boolean(validationErrors.addressLine1)}
          helperText={validationErrors.addressLine1}
          onChange={handleInput}
          InputProps={inputProps}
        />
        <UITextField
          className={classes.textField}
          name="addressLine2"
          label="Address line 2"
          type="text"
          defaultValue={address?.addressLine2}
          variant="outlined"
          size="small"
          fullWidth
          error={Boolean(validationErrors.addressLine2)}
          helperText={validationErrors.addressLine2}
          onChange={handleInput}
          InputProps={inputProps}
        />
        <UITextField
          className={classes.textField}
          name="addressLine3"
          label="Address line 3"
          type="text"
          defaultValue={address?.addressLine3}
          variant="outlined"
          size="small"
          fullWidth
          error={Boolean(validationErrors.addressLine3)}
          helperText={validationErrors.addressLine3}
          onChange={handleInput}
          InputProps={inputProps}
        />
        <UITextField
          className={classes.textField}
          name="addressLine4"
          label="Address line 4"
          type="text"
          defaultValue={address?.addressLine4}
          variant="outlined"
          size="small"
          fullWidth
          error={Boolean(validationErrors.addressLine4)}
          helperText={validationErrors.addressLine4}
          onChange={handleInput}
          InputProps={inputProps}
        />
        <UITextField
          className={classes.textField}
          name="addressLine5"
          label="Address line 5"
          type="text"
          defaultValue={address?.addressLine5}
          variant="outlined"
          size="small"
          fullWidth
          error={Boolean(validationErrors.addressLine5)}
          helperText={validationErrors.addressLine5}
          onChange={handleInput}
          InputProps={inputProps}
        />
        <SelectDropdownLegacy
          name="country"
          label="Country"
          value={requestUpdateBody?.country || ""}
          data={countryList}
          ctrClass={classes.textField}
          onChange={handleInput}
          error={Boolean(validationErrors.country)}
          helperText={validationErrors.country}
          disabled={!canEdit}
        />
        <UITextField
          className={classes.textField}
          name="postCode"
          label="Postcode"
          type="text"
          defaultValue={address?.postCode}
          variant="outlined"
          size="small"
          fullWidth
          error={Boolean(validationErrors.postCode)}
          helperText={validationErrors.postCode}
          onChange={handleInput}
          InputProps={inputProps}
        />
        {canEdit && (
          <UIButton
            variant="contained"
            color="primary"
            onClick={onSubmit}
            data-cy="update-button"
            disabled={isSameAsOrigin()}
            className={classes.btn}
          >
            Update
          </UIButton>
        )}
      </UICard>
    </UIGrid>
  );
}

const useStyles = createStylesheet((theme) => ({
  btn: {
    width: "fit-content",
  },
  textField: {
    marginBottom: theme.spacing(3),
  },
}));
