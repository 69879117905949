import React, { createContext, ReactNode, useContext, useEffect, useMemo, useState } from "react";
import { useQueryClient } from "react-query";
import { useAccountHierarchy, useCostCentresMy, useMyAccounts } from "../../account/hooks";
import { AccountHierarchy, AccountInfo, AccountLevelEnum, StoredAccountHierarchy } from "../../account/types";
import { AccountLevel } from "../../account/utils";
import { useIdentityUser } from "../../auth/hooks";
import { ACCOUNT_CONTEXT } from "../../context/utils";
import { useEnabledDevelopmentFeatures } from "../../development-feature/hooks";
import { useEnabledFeatures } from "../../feature/hooks";
import { useMyPermissions } from "../../permission/hooks";
import { useMyUserType } from "../../user-level/hooks";
import { UserType } from "../../user-level/types";
import { useHubUserExist } from "../../user/hooks";

interface AccountContextType {
  refreshAccountHierarchy: (accountId: string) => void;
  getAccountByLevel: (level: AccountLevelEnum) => AccountInfo | undefined;
  currentAccountLevel: AccountLevel | undefined;
  selectedAccount: AccountInfo | undefined;
  groupId: string | undefined;
  userType: UserType | undefined;
  // Context ID is either cost centre ID, account ID or sub account ID
  contextId: string;
  isReady: boolean;
}

let contextId: string | undefined = undefined;

export const setContextId = (newContextId: string | undefined) => {
  contextId = newContextId;
};

export const getContextId = (): string | undefined => contextId;

const AccountContext = createContext<AccountContextType | undefined>(undefined);

export const useAccountContext = () => {
  const context = useContext(AccountContext);
  if (!context) {
    throw new Error("useAccountContext must be used within AccountProvider");
  }

  return context;
};

interface AccountProviderProps {
  children: ReactNode;
}

export const AccountHierarchyProvider = ({ children }: AccountProviderProps) => {
  const [accountId, setAccountId] = useState<string | undefined>();
  const [costCentreId, setCostCentreId] = useState<string | undefined>();
  const [accountHierarchy, setAccountHierarchy] = useState<AccountHierarchy | null>(null);
  const contextId = costCentreId || accountId || "";
  const queryClient = useQueryClient();
  const groupId = accountHierarchy?.hierarchy[AccountLevel.Group.value]?.id;

  // Try to get user credentials
  const { data: identityUser } = useIdentityUser();
  const isUserReady = Boolean(identityUser?.profile?.sub);

  // Check that user exists in Hub
  const { data: userExists, isFetching: hubUserExistenceFetching } = useHubUserExist({ enabled: Boolean(isUserReady) });

  // Get user type
  const { data: userType } = useMyUserType({ enabled: Boolean(userExists) });
  // Get additional account/cost centre info
  const { data: costCentreInfo } = useCostCentresMy({
    enabled: userType === UserType.COST_CENTRE,
  });

  const { data: groupList } = useMyAccounts({ enabled: userType === UserType.ACCOUNT });

  // Set context ID based on user type
  useEffect(() => {
    if (userType === UserType.COST_CENTRE) {
      localStorage.removeItem(ACCOUNT_CONTEXT);
      setCostCentreId(costCentreInfo?.costCentreId);
    } else if (userType === UserType.ACCOUNT) {
      if (groupList && groupList.length == 1) {
        refreshAccountHierarchy(groupList[0].userAccessibleAccounts[0].id);
      }
    }
  }, [userType, costCentreInfo, groupList, accountId]);

  // Get account hierarchy
  const { data: accountHierarchyData } = useAccountHierarchy(accountId || undefined, {
    enabled: !!accountId,
  });

  useEffect(
    function loadFromQuery() {
      if (accountHierarchyData && identityUser?.profile?.sub) {
        const hierarchy: StoredAccountHierarchy = {
          context: accountHierarchyData,
          userId: identityUser?.profile?.sub,
        };
        setAccountHierarchy(accountHierarchyData);
        localStorage.setItem(ACCOUNT_CONTEXT, JSON.stringify(hierarchy));
      }
    },
    [accountHierarchyData, identityUser?.profile?.sub],
  );

  useEffect(
    function loadIdFromCookie() {
      // Clear account context if we are masquerading user
      if (window.location.pathname === "/jwt-login") {
        localStorage.removeItem(ACCOUNT_CONTEXT);
      }

      const storedAccountHierarchy = localStorage.getItem(ACCOUNT_CONTEXT);
      if (storedAccountHierarchy != null && identityUser?.profile?.sub) {
        const { context: hierarchy, userId } = JSON.parse(storedAccountHierarchy);
        if (userId === identityUser?.profile?.sub && hierarchy) {
          setAccountHierarchy({
            ...hierarchy,
            // Rehydrate the level so we use the same instance and can use it in comparisons
            level: AccountLevel.fromString(hierarchy.level.value),
          });
          setAccountId(hierarchy.hierarchy[hierarchy.level.value].id);
        }
      }
    },
    [identityUser?.profile?.sub],
  );

  useEffect(() => {
    setContextId(contextId);
    queryClient?.invalidateQueries({
      predicate: (query) => {
        const blacklist = ["identityUser", "hubUserExist", "myAccounts"];
        const key: string = String(query.queryKey[0]);
        return !blacklist.includes(key);
      },
    });
  }, [contextId, queryClient]);

  // Fetch permissions and features
  const { isFetching: isPermissionsFetching } = useMyPermissions();
  const { isFetching: isFeatureFetching } = useEnabledFeatures({ enabled: Boolean(contextId) });
  const { isFetching: isDevelopmentFeatureFetching } = useEnabledDevelopmentFeatures({ enabled: Boolean(contextId) });

  const refreshAccountHierarchy = (accountId: string) => {
    setAccountId(accountId);
  };

  const currentAccountLevel = accountHierarchy?.level;
  const selectedAccount = accountHierarchy?.hierarchy[accountHierarchy.level.value];

  const getAccountByLevel = (level: AccountLevelEnum) => {
    return accountHierarchy?.hierarchy[level];
  };

  const isReady = useMemo(() => {
    return !isPermissionsFetching && !hubUserExistenceFetching && !isFeatureFetching && !isDevelopmentFeatureFetching;
  }, [isPermissionsFetching, hubUserExistenceFetching, isFeatureFetching, isDevelopmentFeatureFetching]);

  return (
    <AccountContext.Provider
      value={{
        isReady,
        contextId,
        userType,
        groupId,
        selectedAccount,
        currentAccountLevel,
        refreshAccountHierarchy,
        getAccountByLevel,
      }}
    >
      {children}
    </AccountContext.Provider>
  );
};
