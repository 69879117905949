import { useNavigate } from "react-router-dom";
import Main from "../../_app/layouts/Main";
import { Feature } from "../../feature/types";
import { permissionCodes, useHasPermission } from "../../permission/hooks";
import { useContactCreate } from "../hooks";
import EditContactForm from "../components/EditContactForm";
import { UICard, UIGrid } from "../../_app/components";
import { Contact } from "../types";
import { createStylesheet } from "../../_app/utils/styles";

export const AddContact = () => {
  const classes = useStyles();
  const navigate = useNavigate();

  const {
    mutate: executeContactCreate,
    error: createError,
    isLoading,
  } = useContactCreate({
    onSuccess: () => {
      navigate("/contacts");
    },
  });

  const addContact = (contact: Contact) => {
    executeContactCreate(contact);
  };

  const contact = createError?.config?.data ? JSON.parse(createError.config.data) : {};

  const hasPermissionToManageAllContacts = useHasPermission(permissionCodes.ACCOUNT_CONTACTS);

  return (
    <Main
      title="Add Contact"
      data-cy="add-contact-page"
      accessPermission={[permissionCodes.ACCOUNT_CONTACTS, permissionCodes.ALERTS_ACCESS_FEATURE]}
      feature={Feature.Contacts}
      isLoading={isLoading}
    >
      <UIGrid className={classes.ctr}>
        <UICard>
          <EditContactForm
            contact={contact}
            isSubmitting={isLoading}
            handleSubmit={addContact}
            handleCancel={() => navigate(-1)}
            onlyAlerts={!hasPermissionToManageAllContacts}
          />
        </UICard>
      </UIGrid>
    </Main>
  );
};

const useStyles = createStylesheet((theme) => ({
  ctr: {
    padding: "0",
    marginTop: theme.spacing(1.5),
  },
}));

export default AddContact;
