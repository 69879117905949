import { ReactNode, useEffect } from "react";
import { useBrandName } from "../../brand/hooks";
import { useHasAnyFeatureEnabled } from "../../feature/hooks";
import { Feature } from "../../feature/types";
import { useHasPermission } from "../../permission/hooks";
import { UIAlert, UIGrid, UITypographyProps } from "../components";
import UILoader from "../components/UILoader";
import { createStylesheet, CssBaseline } from "../utils/styles";

interface SecondaryProps {
  children: ReactNode;
  title?: string;
  titleProps?: UITypographyProps;
  isLoading?: boolean;
  accessPermission?: string | undefined;
  feature?: Feature | Feature[];
  className?: string;
}

function Secondary({
  title,
  titleProps,
  isLoading,
  accessPermission = "",
  feature = [],
  children,
  className,
  ...rest
}: SecondaryProps) {
  const classes = useStyles();
  const brandName = useBrandName();
  const hasPermission = useHasPermission(accessPermission, { enabled: Boolean(accessPermission) });

  const features = Array.isArray(feature) ? feature : Array(feature);
  const hasFeature = useHasAnyFeatureEnabled(...features);

  useEffect(() => {
    document.title = `${brandName} ${title ? "- " + title : ""}`;
  }, [brandName, title]);

  const renderContent = () => {
    if (hasPermission && hasFeature) {
      return children;
    }
    return (
      <UIGrid className={classes.error}>
        <UIAlert severity="info">You do not have permission to view this page.</UIAlert>
      </UIGrid>
    );
  };

  return (
    <div className={classes.root} {...rest}>
      <CssBaseline />
      <div className={`${classes.main} ${className}`}>
        {isLoading ? (
          <div className={classes.loader}>
            <UILoader />
          </div>
        ) : (
          <>{renderContent()}</>
        )}
      </div>
    </div>
  );
}

const useStyles = createStylesheet((theme) => ({
  root: {
    minHeight: "100vh",
    width: "100vw",
    backgroundColor: "#ffffff",
  },
  main: {
    minWidth: "100%",
    minHeight: "100vh",
  },
  error: {
    padding: theme.spacing(2),
  },
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    flex: 1,
  },
}));

export default Secondary;
