import { ChangeEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UIAlert, UIButton, UICard, UILoader } from "../../_app/components";
import UISimpleDialog from "../../_app/components/UISimpleDialog";
import { useFeedbackAlerts } from "../../_app/hooks";
import Main from "../../_app/layouts/Main";
import { createStylesheet } from "../../_app/utils/styles";
import { genericError } from "../../_app/utils/text";
import { Feature } from "../../feature/types";
import SelectDropdown, { Option } from "../../form/components/SelectDropdown";
import { permissionCodes } from "../../permission/hooks";
import { useLineAssetsInfinite, usePerformLineTest } from "../hooks";
import { LineTestResponse } from "../types";

export const LineTest = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { setFeedbackAlertError } = useFeedbackAlerts();
  const [selectedAsset, setSelectedAsset] = useState<string>("");
  const [search, setSearch] = useState("");
  const [showPerformModal, setShowPerformModal] = useState(false);
  const [showFaultModal, setShowFaultModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [lineTestResponse, setLineTestResponse] = useState<LineTestResponse>();

  const { data: assets, isFetching, fetchNextPage, hasNextPage } = useLineAssetsInfinite(25, search);
  const assetClisParsed = assets?.pages.reduce<Option[]>((acc, page) => {
    return [
      ...acc,
      ...page?.map((c) => ({
        id: c,
        label: c,
        value: c,
      })),
    ];
  }, []);

  const performLineTestMutation = usePerformLineTest();

  const performLineTest = () => {
    setShowPerformModal(true);
    performLineTestMutation.mutate(selectedAsset, {
      onSuccess: (data: LineTestResponse) => {
        setShowPerformModal(false);
        setLineTestResponse(data);
        if (data.status === "success") {
          setShowSuccessModal(true);
        } else {
          setShowFaultModal(true);
        }
      },
      onError: (error: any) => {
        setShowPerformModal(false);
        setFeedbackAlertError(error?.data?.message || genericError());
      },
    });
  };

  const raiseSupportTicket = () => {
    navigate("/tickets/new", {
      state: {
        payload: {
          cli: selectedAsset,
          requestType: "Fault",
          subject: "Fault - Test result fail",
          description: "Fault located at exchange. " + (lineTestResponse?.message || ""),
        },
      },
    });
  };

  return (
    <Main
      title="Line Test"
      data-cy="line-test-page"
      feature={Feature.DclSupport}
      accessPermission={permissionCodes.SUPPORT_ACCESS}
      needSelectedAccount={true}
    >
      <UICard className={classes.card}>
        <UIAlert severity="info" className={classes.info}>
          <div>
            <strong>Info</strong>
          </div>
          <ol className={classes.infoList}>
            <li>
              Completing a Line Test in most occasions will confirm where the fault is currently located, whether this be within
              the openreach network or suggested to be with the onsite equipment or wiring.
            </li>
            <li>Please note a test can be completed as many times as you wish.</li>
          </ol>
        </UIAlert>
        <UIAlert severity="warning" className={classes.info}>
          Tests can only be completed when the line is not in use so please ensure there is no current active calls ongoing
          because this will interfere with the test results.
        </UIAlert>
        <SelectDropdown
          name="clis"
          label="CLI/Identifier"
          value={selectedAsset}
          data={assetClisParsed || []}
          ctrClass={classes.cliList}
          infiniteScroll={true}
          hasSearch={true}
          multiple={false}
          multiline
          nextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          isFetching={isFetching}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            setSelectedAsset(event.target.value);
          }}
          searchOn={(q: string) => {
            setSearch(q);
          }}
          resetOnClose={false}
        />
        <UIButton
          disabled={!selectedAsset}
          variant="contained"
          color="primary"
          data-cy="perform-button"
          onClick={performLineTest}
          className={classes.submitBtn}
        >
          Perform Line Test
        </UIButton>
        <UISimpleDialog
          title="Running Line Test"
          open={Boolean(showPerformModal)}
          actions={[
            {
              label: "Close",
              props: {
                onClick: () => setShowPerformModal(false),
                variant: "outlined",
              },
            },
          ]}
        >
          This may take a few minutes
          <UILoader linear color="primary" className={classes.progress} />
        </UISimpleDialog>
        <UISimpleDialog
          title="Fault Found"
          open={Boolean(showFaultModal)}
          actions={[
            {
              label: "Raise Support Ticket",
              props: {
                onClick: raiseSupportTicket,
                variant: "contained",
                color: "primary",
              },
            },
            {
              label: "Close",
              props: {
                onClick: () => setShowFaultModal(false),
                variant: "outlined",
              },
            },
          ]}
        >
          <div>Test result fail - fault located at exchange</div>
          <div>Description: {lineTestResponse?.message}</div>
        </UISimpleDialog>
        <UISimpleDialog
          title="No Fault Found"
          open={Boolean(showSuccessModal)}
          actions={[
            {
              label: "Close",
              props: {
                onClick: () => setShowSuccessModal(false),
                variant: "outlined",
              },
            },
          ]}
        >
          <></>
        </UISimpleDialog>
      </UICard>
    </Main>
  );
};

const useStyles = createStylesheet((theme) => ({
  card: {
    width: "100%",
    marginTop: theme.spacing(1.5),
    "& >:first-of-type": {
      maxWidth: "90%",
      gap: theme.spacing(3),
    },
  },
  cliList: {
    maxWidth: 400,
  },
  submitBtn: {
    maxWidth: 180,
  },
  info: {
    width: "fit-content",
    alignItems: "center",
  },
  infoList: {
    marginBottom: "15px",
    paddingInlineStart: "15px",
    marginBlockStart: "0em",
    marginBlockEnd: "0em",
  },
  progress: {
    marginTop: "40px",
  },
}));

export default LineTest;
