import { useNavigate, useParams } from "react-router-dom";
import Main from "../../_app/layouts/Main";
import { Feature } from "../../feature/types";
import { permissionCodes, useHasPermission } from "../../permission/hooks";
import EditContactForm from "../components/EditContactForm";
import { useContactUpdate, useSingleContact } from "../hooks";
import { UICard, UIGrid } from "../../_app/components";
import { Contact } from "../types";
import { createStylesheet } from "../../_app/utils/styles";

export const EditContact = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const classes = useStyles();

  const { data: contact, isFetching: contactFetching, isSuccess: contactSuccess } = useSingleContact(id ?? "");

  const { mutate: executeContactUpdate, isLoading: isUpdating } = useContactUpdate({
    onSuccess: () => {
      navigate("/contacts");
    },
  });

  const onSubmit = (contact: Contact) => executeContactUpdate(contact);
  const hasPermissionToManageAllContacts = useHasPermission(permissionCodes.ACCOUNT_CONTACTS);

  return (
    <Main
      title="Edit Contact"
      data-cy="edit-contact-page"
      accessPermission={[permissionCodes.ACCOUNT_CONTACTS, permissionCodes.ALERTS_ACCESS_FEATURE]}
      feature={Feature.Contacts}
      isLoading={contactFetching}
    >
      {contactSuccess && (
        <UIGrid className={classes.ctr}>
          <UICard>
            <EditContactForm
              contact={contact}
              isSubmitting={isUpdating}
              handleSubmit={onSubmit}
              handleCancel={() => navigate(-1)}
              onlyAlerts={!hasPermissionToManageAllContacts}
            />
          </UICard>
        </UIGrid>
      )}
    </Main>
  );
};

const useStyles = createStylesheet((theme) => ({
  ctr: {
    padding: "0",
    marginTop: theme.spacing(1.5),
  },
}));

export default EditContact;
