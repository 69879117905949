import { UIButton, UILoader } from "../../../_app/components";
import WorldwideDataCapDetail from "./data-roaming/WorldwideDataCapDetail";
import DetailsCard from "./DetailsCard";
import DetailItem from "./DetailItem";
import { Asset } from "../../types";
import { useHasAnyFeatureEnabled } from "../../../feature/hooks";
import { useSingleMobileDetails } from "../../hooks";
import { createStylesheet } from "../../../_app/utils/styles";
import { Feature } from "../../../feature/types";

interface Props {
  showBars: () => void;
  hasBars: boolean;
  asset: Asset;
}

export const MobileDetails = ({ showBars, hasBars, asset }: Props) => {
  const classes = useStyles();
  const { data: mobileAsset, isLoading } = useSingleMobileDetails(asset.id);

  const hasWorldwideDataRoaming = useHasAnyFeatureEnabled(Feature.WorldwideDataRoaming);

  return isLoading ? (
    <UILoader data-cy={"wdr-loader"} size={25} />
  ) : (
    <DetailsCard title="Mobile Details">
      <>
        <DetailItem title="Provider" value={mobileAsset?.network ?? "N/A"} />
        <DetailItem title="SIM Number" value={mobileAsset?.simNumber ?? "N/A"} />
        {hasBars && (
          <DetailItem
            title="Bars"
            value=""
            action={
              <UIButton
                aria-label="manage-btn"
                variant="text"
                onClick={showBars}
                className={classes.button}
                data-cy="manage-btn"
                id="manage-btn"
              >
                Manage
              </UIButton>
            }
          />
        )}
        {hasWorldwideDataRoaming && <WorldwideDataCapDetail assetId={asset.id} />}
      </>
    </DetailsCard>
  );
};

const useStyles = createStylesheet(() => ({
  button: {
    justifyContent: "flex-end",
  },
}));

export default MobileDetails;
