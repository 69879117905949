import { createStylesheet, Theme, useMediaQuery } from "../../_app/utils/styles";
import { UIBreadcrumbs, UITooltip, UITypography } from "../../_app/components";
import { useAccountContext } from "../../_app/providers/AccountHierarchyProvider";
import { AccountInfo, AccountLevelEnum } from "../../account/types";
import { AccountLevel } from "../../account/utils";

const HierarchyBreadcrumbs = () => {
  const classes = useStyles();
  const { getAccountByLevel } = useAccountContext();
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));

  const showCodePrefix = (accountLevel: AccountLevel) => {
    if (isSmallScreen) return false;
    return accountLevel === AccountLevel.Account || accountLevel === AccountLevel.SubAccount;
  };

  const headAccount = getAccountByLevel(AccountLevelEnum.Account);
  const subAccount = getAccountByLevel(AccountLevelEnum.SubAccount);

  const createLabel = (account: AccountInfo) => {
    const accountLevel = AccountLevel.fromString(account.level);

    return (
      <UITooltip title={isSmallScreen ? account.name : account.code || ""} arrow key={account.id}>
        <UITypography variant="subtitle2" color="textPrimary" className={classes.levelOverflow}>
          <>
            <strong>{AccountLevel.fromString(account.level)?.label}</strong>:&nbsp;
          </>
          {accountLevel && showCodePrefix(accountLevel) && `(${account.code}) `}
          {account.name}
        </UITypography>
      </UITooltip>
    );
  };

  const crumbs = [headAccount, subAccount].filter((account) => account !== undefined).map((account) => createLabel(account));

  return (
    <>
      <UIBreadcrumbs
        separator={<UITypography className={classes.comma}>,</UITypography>}
        className={classes.breadcrumbsWrap}
        data-cy="user-level-breadcrumbs"
      >
        {crumbs}
      </UIBreadcrumbs>
      {crumbs.length > 0 && (
        <UITypography variant="subtitle2" color="textPrimary" fontWeight={"bolder"}>
          <span>|&nbsp;</span>
        </UITypography>
      )}
    </>
  );
};

const useStyles = createStylesheet((theme) => ({
  breadcrumbsWrap: {
    padding: "0 8px",
    marginLeft: "250px",
    "& > ol": {
      justifyContent: "flex-end",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0px",
      maxHeight: "56px",
    },
  },
  comma: {
    color: theme.palette.tertiary.main,
    marginLeft: "-8px",
  },
  levelOverflow: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}));

export default HierarchyBreadcrumbs;
