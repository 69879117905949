import { Bar } from "react-chartjs-2";
import { createStylesheet } from "../../../_app/utils/styles";
import { formatPrice } from "../../../billing/utils";
import { Query } from "../../../filter/types";
import { useHorizontalBarChartOptions, useServiceChargesFrequency } from "../../hooks";
import GraphWidget from "./GraphWidget";

interface Props {
  queries: Query[];
}

const ServiceChargesFrequency = ({ queries }: Props) => {
  const classes = useStyles();
  const { data = [], isFetching, isError } = useServiceChargesFrequency(queries);
  const hasError = isError || data?.length <= 0;

  const options = useHorizontalBarChartOptions(
    data.unit,
    undefined,
    (context: any) => {
      return formatPrice({
        gross: context?.raw,
        net: context?.raw,
        vat: 0,
        unit: data.unit,
      });
    },
    true,
  );
  return (
    <GraphWidget title="Service Charges Frequency" isLoading={isFetching} hasError={hasError} width={12}>
      <div className={classes.horizontalBarWrap}>
        <Bar data={data} options={options} />
      </div>
    </GraphWidget>
  );
};

const useStyles = createStylesheet((theme) => ({
  horizontalBarWrap: {
    position: "relative",
    minHeight: 410,
    width: "100%",
  },
}));

export default ServiceChargesFrequency;
