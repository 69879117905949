import { ChangeEvent, useState } from "react";
import Main from "../../_app/layouts/Main";
import { useIdentityUser } from "../../auth/hooks";
import { useNonCognitoUserUpdate } from "../hooks";
import { UserDetails } from "../types";
import { IdentityUser } from "../../auth/types";
import { createStylesheet } from "../../_app/utils/styles";
import { UIButton, UICard, UIGrid, UITextField } from "../../_app/components";
import { UserType } from "../../user-level/types";

export const MyDetails = () => {
  const classes = useStyles();
  const { data: identityUser, isLoading: isLoadingIdentityUser } = useIdentityUser();

  const userData: UserDetails = {
    lastName: identityUser?.profile?.family_name ?? "",
    firstName: identityUser?.profile?.given_name ?? "",
    email: identityUser?.profile?.email ?? "",
  };

  const [requestUpdateBody, setRequestUpdateBody] = useState<UserDetails>(userData);
  const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
    setRequestUpdateBody({
      ...requestUpdateBody,
      [event.target.name]: event.target.value,
    });
  };

  const { updateUser, isLoading: isUpdating } = useNonCognitoUserUpdate();

  const handleUpdate = () => {
    if (requestUpdateBody?.email) {
      updateUser(identityUser as IdentityUser, requestUpdateBody);
    }
  };

  const isLoading = isLoadingIdentityUser || isUpdating;

  return (
    <Main
      title="My Details"
      data-cy="my-details-page"
      isLoading={isLoading}
      allowedUserTypes={[UserType.ACCOUNT, UserType.COST_CENTRE]}
    >
      <UIGrid size={{ xs: 12, md: 6 }} data-cy="my-details-form">
        <UICard elevation={1} className={classes.card} padding="32px">
          <UITextField
            key={identityUser?.profile.email}
            className={classes.textField}
            name="email"
            label="Email address/Username"
            type="email"
            defaultValue={identityUser?.profile.email}
            variant="outlined"
            size="small"
            fullWidth
            onChange={handleInput}
          />
          <UITextField
            key={identityUser?.profile.given_name}
            className={classes.textField}
            name="firstName"
            label="First Name"
            type="text"
            defaultValue={identityUser?.profile.given_name}
            variant="outlined"
            size="small"
            fullWidth
            onChange={handleInput}
          />
          <UITextField
            key={identityUser?.profile.family_name}
            className={classes.textField}
            name="lastName"
            label="Last Name"
            type="text"
            defaultValue={identityUser?.profile.family_name}
            variant="outlined"
            size="small"
            fullWidth
            onChange={handleInput}
          />
          <UIButton variant="contained" color="primary" onClick={handleUpdate} data-cy="update-button">
            Update
          </UIButton>
        </UICard>
      </UIGrid>
    </Main>
  );
};

const useStyles = createStylesheet((theme) => ({
  card: {
    padding: theme.spacing(4),
  },
  textField: {
    marginBottom: theme.spacing(4),
  },
}));

export default MyDetails;
