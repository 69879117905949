import { Bar } from "react-chartjs-2";
import { createStylesheet } from "../../../_app/utils/styles";
import { Query } from "../../../filter/types";
import { useCallCost, useVerticalBarChartOptions } from "../../hooks";
import GraphWidget from "./GraphWidget";

interface Props {
  queries: Query[];
}

const CallCost = ({ queries }: Props) => {
  const classes = useStyles();
  const { data = [], isFetching, isError } = useCallCost(queries);
  const hasError = isError || data?.datasets?.length <= 0;
  const options = useVerticalBarChartOptions(data?.unit);

  return (
    <GraphWidget title="Call Cost" isLoading={isFetching} hasError={hasError}>
      <div className={classes.barWrap}>
        <Bar data={data} options={options} />
      </div>
    </GraphWidget>
  );
};

const useStyles = createStylesheet(() => ({
  barWrap: {
    position: "relative",
    minHeight: 410,
    width: "100%",
  },
}));

export default CallCost;
