import { useQuery } from "react-query";
import { AjaxResponse } from "../_app/api";
import { getAllUserLevels, getMyUserType } from "./api";
import { UserLevel, UserLevelFlag, UserType } from "./types";
import { AccountLevel } from "../account/utils";
import { useAccountContext } from "../_app/providers/AccountHierarchyProvider";

export function useAllUserLevels(options = {}) {
  return useQuery<UserLevel[], AjaxResponse>(["user-levels"], () => getAllUserLevels(), {
    keepPreviousData: true,
    ...options,
  });
}

export function useMyUserType(options = {}) {
  return useQuery<UserType, AjaxResponse>(["user-type-my"], () => getMyUserType(), {
    ...options,
  });
}

export function useLeafUserLevel() {
  const { data: levels } = useAllUserLevels();
  return levels?.filter((l) => l.flag !== UserLevelFlag.Individual).reduce((max, cell) => Math.max(max, cell.id), 0) || 0;
}

export function useSubUserLevel() {
  return useUserLevel(UserLevelFlag.Sub);
}

export function useIndividualUserLevel() {
  return useUserLevel(UserLevelFlag.Individual);
}

export function useHeadUserLevel() {
  return useUserLevel(UserLevelFlag.Head);
}

export function useIsHeadOrLoweUserLevel(account: any) {
  const headLevel = useHeadUserLevel();
  return account?.id && headLevel && account?.level?.id >= headLevel?.id;
}

export function useUserLevel(level?: UserLevelFlag) {
  const { data: levels } = useAllUserLevels();
  return levels?.find((l) => l?.flag === level);
}

export function useTopUserLevel() {
  const { data: levels } = useAllUserLevels();
  return [...(levels || [])]?.sort((a, b) => a?.id - b?.id)?.[0];
}

export function useLowestUserLevel() {
  const { data: levels } = useAllUserLevels();
  return [...(levels || [])]?.sort((a, b) => b?.id - a?.id)?.[0];
}

export function useHasAccountLevelAccess(level?: AccountLevel) {
  const { currentAccountLevel } = useAccountContext();
  if (!level) return true;

  return (currentAccountLevel?.id || 0) <= level.id;
}

export function useUserLevelPrefix() {
  const { currentAccountLevel } = useAccountContext();

  return currentAccountLevel?.label || "";
}

export function useUserLevelMap() {
  const { data: userLevels } = useAllUserLevels();
  const map: any = {};
  userLevels?.forEach((level) => (map[level?.id] = level));
  return map;
}
