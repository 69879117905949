import { createContext, Dispatch, PropsWithChildren, useReducer } from "react";
import { Brand } from "../../brand/types";
import Reducer from "./Reducer";

export interface StoreState {
  error: AppAlert | null;
  success: AppAlert | null;
  alert: AppAlert | null;
  brand: Brand | null;
  isNestedApp?: boolean;
}

export interface AppAlert {
  message?: string;
  duration?: number;
}

export interface Action {
  type: string;
  payload?: any;
  skipStorage?: boolean;
}

const initial: StoreState = {
  error: null,
  success: null,
  alert: null,
  brand: null,
  isNestedApp: localStorage.getItem("isNestedApp") === "true",
};

const Store = ({ children, initialState }: PropsWithChildren<{ initialState?: Partial<StoreState> }>) => {
  const [state, dispatch]: [StoreState, Dispatch<Action>] = useReducer(Reducer, { ...initial, ...initialState });
  return <StoreContext.Provider value={[state, dispatch]}>{children}</StoreContext.Provider>;
};

export const StoreContext = createContext<any>(initial);
export default Store;
