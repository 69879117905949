import api from "../_app/api";
import { convertFiltersToQueries } from "../filter/api";
import { Bar, BarUpdateParams, GetBarsResponse } from "./types";

export const getBars = async (accountId: string, params: any = {}) => {
  const { data }: { data: GetBarsResponse["bars"] } = await api({
    method: "GET",
    url: `/mobile-assets/accounts/${accountId}/bars`,
    params,
  });
  return data;
};

export const getBarsForCostCentre = async (costCentreId: string, params: any = {}) => {
  const { data }: { data: GetBarsResponse["bars"] } = await api({
    method: "GET",
    url: `/mobile-assets/cost-centres/${costCentreId}/bars`,
    params,
  });
  return data;
};

export const getBarsByAssetId = async (assetId: string) => {
  const { data }: { data: GetBarsResponse } = await api({
    method: "GET",
    url: `/mobile-assets/${assetId}/bars`,
  });
  return data;
};

export const getCostCenterBarsByAssetId = async (costCentreId: string, assetId: string) => {
  const { data }: { data: GetBarsResponse } = await api({
    method: "GET",
    url: `/mobile-assets/${assetId}/cost-centres/${costCentreId}/bars`,
  });
  return data;
};

export const getBarById = async (barId: string, assetId: string) => {
  const { data }: { data: Bar } = await api({
    method: "GET",
    url: `/mobile-assets/${assetId}/bars/${barId}`,
  });
  return data;
};

export const getBarByIdCostCentre = async (costCentreId: string, barId: string, assetId: string) => {
  const { data }: { data: Bar } = await api({
    method: "GET",
    url: `/mobile-assets/${assetId}/cost-centres/${costCentreId}/bars/${barId}`,
  });
  return data;
};

export const updateBars = async (accountId: string, params: BarUpdateParams) => {
  const parsedParams = convertFiltersToQueries(params);
  await api({
    method: "PUT",
    url: `/mobile-assets/accounts/${accountId}/bars`,
    data: parsedParams,
  });
  return "Thank you for your request, the bar will be added within the next 3 working hours and may take up to 24 hours to fully complete.";
};

export const updateBarsForCostCentre = async (costCentreId: string, params: BarUpdateParams) => {
  const parsedParams = convertFiltersToQueries(params);
  await api({
    method: "PUT",
    url: `/mobile-assets/cost-centres/${costCentreId}/bars`,
    data: parsedParams,
  });

  return "Thank you for your request, the bar will be added within the next 3 working hours and may take up to 24 hours to fully complete.";
};

export const getBarsForClis = async (clis: any[]) => {
  return [
    {
      cli: "07664488336",
      account: "YWJ35776",
      bars: [
        { id: "1", label: "Stolen", value: false },
        {
          id: "2",
          label: "GPRS",
          value: false,
          disabled: true,
          disabledReason: "Bar not supported for this cli",
        },
        { id: "3", label: "Incoming Calls", value: false },
        { id: "4", label: "Incoming SMS", value: true },
        { id: "5", label: "Premium Rate", value: true },
        { id: "6", label: "Premium Call", value: true },
        { id: "7", label: "Outgoing MMS", value: false },
        { id: "8", label: "Outgoing Roaming", value: true },
        { id: "9", label: "International Roaming", value: false },
      ],
    },
    {
      cli: "07005577993",
      account: "YWJ35776",
      bars: [
        { id: "1", label: "Stolen", value: true },
        { id: "2", label: "GPRS", value: false },
        { id: "3", label: "Incoming Calls", value: false },
        { id: "4", label: "Incoming SMS", value: false },
        { id: "5", label: "Premium Rate", value: true },
        { id: "6", label: "Premium Call", value: false },
        { id: "7", label: "Outgoing MMS", value: false },
        { id: "8", label: "Outgoing Roaming", value: false },
        { id: "9", label: "International Roaming", value: true },
      ],
    },
    {
      cli: "07994466113",
      account: "YWJ35776",
      bars: [
        { id: "1", label: "Stolen", value: true },
        { id: "2", label: "GPRS", value: true },
        { id: "3", label: "Incoming Calls", value: true },
        { id: "4", label: "Incoming SMS", value: true },
        { id: "5", label: "Premium Rate", value: true },
        { id: "6", label: "Premium Call", value: false, disabled: true },
        { id: "7", label: "Outgoing MMS", value: false },
        { id: "8", label: "Outgoing Roaming", value: true },
        { id: "9", label: "International Roaming", value: true },
      ],
    },
    {
      cli: "07553322441",
      account: "ABC312345",
      bars: [
        { id: "1", label: "Stolen", value: true },
        { id: "2", label: "GPRS", value: false },
        { id: "3", label: "Incoming Calls", value: false },
        { id: "4", label: "Incoming SMS", value: false },
        { id: "5", label: "Premium Rate", value: true },
        { id: "6", label: "Premium Call", value: false },
        { id: "7", label: "Outgoing MMS", value: true },
      ],
    },
    {
      cli: "07675567567",
      account: "ABC312345",
      bars: [
        { id: "1", label: "Stolen", value: false },
        { id: "2", label: "GPRS", value: true },
        { id: "3", label: "Incoming Calls", value: false },
        { id: "4", label: "Incoming SMS", value: true },
        { id: "5", label: "Premium Rate", value: true },
        { id: "6", label: "Premium Call", value: true },
        { id: "7", label: "Outgoing MMS", value: false },
        { id: "8", label: "Outgoing Roaming", value: true },
        {
          id: "9",
          label: "International Roaming",
          value: false,
          disabled: true,
          disabledReason: "Bar not supported for this cli",
        },
      ],
    },
    {
      cli: "07896785786",
      account: "ABC312345",
      bars: [
        { id: "1", label: "Stolen", value: false },
        { id: "2", label: "GPRS", value: false },
        { id: "3", label: "Incoming Calls", value: false },
        {
          id: "4",
          label: "Incoming SMS",
          value: false,
          disabled: true,
          disabledReason: "Bar not supported for this cli",
        },
        { id: "5", label: "Premium Rate", value: true },
        { id: "6", label: "Premium Call", value: true },
        { id: "7", label: "Outgoing MMS", value: false },
        { id: "8", label: "Outgoing Roaming", value: false },
        { id: "9", label: "International Roaming", value: true },
      ],
    },
    {
      cli: "079978867567",
      account: "ABC312345",
      bars: [
        { id: "1", label: "Stolen", value: true },
        { id: "2", label: "GPRS", value: true },
        { id: "3", label: "Incoming Calls", value: true },
        { id: "6", label: "Premium Call", value: false },
        { id: "7", label: "Outgoing MMS", value: false },
        { id: "8", label: "Outgoing Roaming", value: true },
        { id: "9", label: "International Roaming", value: true },
      ],
    },
    {
      cli: "07333545666",
      account: "ABC312345",
      bars: [
        { id: "1", label: "Stolen", value: true },
        { id: "2", label: "GPRS", value: false },
        { id: "3", label: "Incoming Calls", value: false },
        { id: "4", label: "Incoming SMS", value: false },
        { id: "8", label: "Outgoing Roaming", value: false },
        { id: "9", label: "International Roaming", value: true },
      ],
    },
  ];
};
