import { QueryOptions, useInfiniteQuery, useMutation, useQuery, useQueryClient } from "react-query";
import { AjaxResponse, IdValueQuery } from "../_app/api";
import {
  clearBillLimit,
  clearBillLimitCostCentre,
  getAssetById,
  getAssets,
  getAssetsByCostCentre,
  getAssetsCsv,
  getAssetsOperationHistory,
  getAssetsOperationHistoryCostCentre,
  getBillLimit,
  getBillLimitCostCentre,
  getBillLimitPrefs,
  getClis,
  getLineAssetClis,
  getMobileAssets,
  getMobileAssetsForCostCentre,
  getMobileAssetsWithEligibility,
  getMobileDetailsByAssetId,
  getMobileNetworks,
  getMobileNetworksForCostCentres,
  getServiceDetails,
  getServiceSettings,
  orderSim,
  orderSimCostCentre,
  performLineTest,
  searchAssets,
  updateBillLimit,
  updateBillLimitCostCentre,
  updateBillLimitPrefs,
  updateTag,
} from "./api";
import {
  Address,
  Asset,
  BillLimit,
  ClearBillLimitParams,
  GetAssetsOperationHistoryResponse,
  GetAssetsResponse,
  GetClisResponse,
  MobileAsset,
  MobileAssetWithEligibility,
  MobileDetails,
  SearchAsset,
  UpdateBillLimitParams,
  UpdateBillLimitPrefsParams,
} from "./types";
import { downloadFile, extractFilenameFromHeaders, isObject } from "../_app/utils";
import { assetFilters, mobileManagementFilters } from "./filterConfig";
import { useFeedbackAlerts } from "../_app/hooks";
import { genericError } from "../_app/utils/text";
import { Page } from "../_app/api/types";
import { AxiosResponse } from "axios";
import { useAccountContext } from "../_app/providers/AccountHierarchyProvider";
import { UserType } from "../user-level/types";

export function useAssets(liveOnly: boolean, page: number, limit: number, queries?: any[], options = {}) {
  return useQuery<GetAssetsResponse, AjaxResponse>(
    ["assets", { liveOnly, page, limit, queries }],
    () => getAssets(liveOnly, page, limit, queries),
    { ...options },
  );
}

export function useLiveAssets(page: number, limit: number, queries?: any[], options = {}) {
  return useQuery<GetAssetsResponse, AjaxResponse>(
    ["assets-live", { page, limit, queries }],
    () => getAssets(true, page, limit, queries),
    { ...options },
  );
}

export function useAssetsByCostCentre(costCentre: string = "unassigned", page: number, limit: number, options = {}) {
  return useQuery<GetAssetsResponse, AjaxResponse>(
    ["assets-by-cost-centre", costCentre, { page, limit }],
    () => getAssetsByCostCentre(costCentre, page, limit),
    { ...options },
  );
}

export function useAssetsFilters() {
  return { data: assetFilters };
}

export function useExportAssets(options?: QueryOptions) {
  const { setFeedbackAlertError } = useFeedbackAlerts();
  return useQuery(["export-assets"], () => getAssetsCsv(), {
    enabled: false,
    cacheTime: 0,
    onError: (err) => {
      setFeedbackAlertError((err as AxiosResponse).data?.message || "Cannot download asset export due to an error.");
    },
    onSuccess: (response: AxiosResponse) => {
      const filename = extractFilenameFromHeaders(response) || "asset-export.csv";
      downloadFile(response?.data, filename);
    },
    ...options,
  });
}

export function useMobileManagementFilters() {
  return { data: mobileManagementFilters };
}

export function useClisInfinite(query?: string, options = {}) {
  return useInfiniteQuery<GetClisResponse>(["clis-inf", query], ({ pageParam = 0 }) => getClis(query, pageParam), {
    getNextPageParam: (lastPage: string[], allPages: string[][]) => {
      if (lastPage.length === 0) {
        return undefined;
      } else {
        return allPages.length;
      }
    },
    ...options,
  });
}

export function useLineAssetsInfinite(pageSize: number = 25, query: string, options = {}) {
  return useInfiniteQuery<GetClisResponse>(
    ["line-assets-inf", query],
    ({ pageParam = 0 }) => getLineAssetClis(pageParam, pageSize, query),
    {
      getNextPageParam: (lastPage: string[], allPages: string[][]) => {
        if (lastPage.length === 0) {
          return undefined;
        } else {
          return allPages.length;
        }
      },
      ...options,
    },
  );
}

export function useSingleAsset(id: string, options = {}) {
  const { setFeedbackAlertError } = useFeedbackAlerts();

  return useQuery<Asset, AjaxResponse>(["asset", id], () => getAssetById(id), {
    onError: () => {
      setFeedbackAlertError("Could not found asset details");
    },
    ...options,
  });
}

export function useServiceSettings(assetId: string, options = {}) {
  return useQuery<any, AjaxResponse>(["serviceSettings", assetId], () => getServiceSettings(assetId), { ...options });
}

export function useServiceDetails(assetId: string, page: number, limit: number, options = {}) {
  return useQuery<any, AjaxResponse>(
    ["serviceDetails", { assetId, page, limit }],
    () => getServiceDetails(assetId, page, limit),
    { ...options },
  );
}

export function useSingleMobileDetails(assetId: string, options = {}) {
  const { setFeedbackAlertError } = useFeedbackAlerts();

  return useQuery<MobileDetails, AjaxResponse>(["assetId", assetId], () => getMobileDetailsByAssetId(assetId), {
    onError: () => {
      setFeedbackAlertError("Could not found mobile details");
    },
    ...options,
  });
}

export function useMobileAssets(page: number, limit: number, queries?: any[], clis?: string[], options = {}) {
  const { contextId, userType } = useAccountContext();

  return useQuery<Page<MobileAsset>, AjaxResponse>(
    ["mobile-assets", { page, limit, queries, clis, contextId }],
    () => {
      if (userType === UserType.COST_CENTRE) {
        return getMobileAssetsForCostCentre(contextId, page, limit, queries);
      } else {
        return getMobileAssets(
          contextId,
          page,
          limit,
          queries,
          clis?.length ? clis?.reduce((f, s) => `${f},${s}`, "") : undefined,
        );
      }
    },
    { ...options },
  );
}

export function useMobileAssetsWithEligibility(page: number, limit: number, queries?: any[], clis?: string[], options = {}) {
  const { selectedAccount } = useAccountContext();
  const accountId = selectedAccount?.id;

  return useQuery<Page<MobileAssetWithEligibility>, AjaxResponse>(
    ["mobile-assets-with-eligibility", { page, limit, queries, clis, accountId }],
    () =>
      getMobileAssetsWithEligibility(page, limit, queries, clis?.length ? clis?.reduce((f, s) => `${f},${s}`, "") : undefined),
    { ...options },
  );
}

export function useMobileNetworks(assetIds?: string[], filters?: IdValueQuery[], options = {}) {
  const { contextId, userType } = useAccountContext();

  return useQuery<string[], AjaxResponse>(
    ["mobile-networks", { assetIds, filters }],
    () => {
      if (userType === UserType.COST_CENTRE) {
        return getMobileNetworksForCostCentres(contextId, assetIds, filters);
      } else {
        return getMobileNetworks(contextId, assetIds, filters);
      }
    },
    { ...options },
  );
}

export function useBillLimit(assetIds: any[], page: number, limit: number, queries?: any[], options = {}) {
  const { contextId, userType } = useAccountContext();

  const callback = userType === UserType.COST_CENTRE ? getBillLimitCostCentre : getBillLimit;

  return useQuery<any, AjaxResponse, Page<BillLimit>>(
    ["bill-limit", { assetIds, page, limit, queries }],
    () => callback(contextId, assetIds, page, limit, queries),
    {
      staleTime: 0,
      ...options,
    },
  );
}

export function useBillLimitPrefs(assetId?: string, options = {}) {
  const { setFeedbackAlertError } = useFeedbackAlerts();

  return useQuery(["bill-limit-prefs", assetId], () => getBillLimitPrefs(assetId), {
    ...options,
    onError: () => {
      setFeedbackAlertError(genericError());
    },
  });
}

export function useUpdateBillLimitPrefs(options = {}) {
  const { setFeedbackAlertError } = useFeedbackAlerts();

  return useMutation<string, AjaxResponse, UpdateBillLimitPrefsParams>(updateBillLimitPrefs, {
    ...options,
    onError: () => {
      setFeedbackAlertError("Cannot perform update");
    },
  });
}

export function useOrderSim(assetId: string, simType: string, options = {}) {
  const { contextId, userType } = useAccountContext();

  return useMutation<string, AjaxResponse, any>(
    (address: Address) =>
      userType === UserType.COST_CENTRE
        ? orderSimCostCentre(contextId, assetId, address, simType)
        : orderSim(assetId, address, simType),
    {
      ...options,
    },
  );
}

export function useUpdateBillLimit(options: any = {}) {
  const { setFeedbackAlertError, setFeedbackAlertSuccess } = useFeedbackAlerts();
  const { contextId, userType } = useAccountContext();
  const callback = userType === UserType.COST_CENTRE ? updateBillLimitCostCentre : updateBillLimit;

  return useMutation<string, AjaxResponse, UpdateBillLimitParams>((params) => callback(contextId, params), {
    ...options,
    onError: (error) => {
      setFeedbackAlertError(error?.data?.message || genericError());
    },
    onSuccess: (updateLimitResponse) => {
      setFeedbackAlertSuccess(updateLimitResponse);
      options?.onSuccess?.();
    },
  });
}

export function useClearBillLimit(options: any = {}) {
  const { setFeedbackAlertError, setFeedbackAlertSuccess } = useFeedbackAlerts();
  const { contextId, userType } = useAccountContext();
  const callback = userType === UserType.COST_CENTRE ? clearBillLimitCostCentre : clearBillLimit;

  return useMutation<boolean, AjaxResponse, ClearBillLimitParams>((params) => callback(contextId, params), {
    ...options,
    onError: (error) => {
      setFeedbackAlertError(error?.data?.message || genericError());
    },
    onSuccess: (clearLimitResponse) => {
      setFeedbackAlertSuccess(clearLimitResponse);
      options?.onSuccess?.();
    },
  });
}

export function useUpdateTag(options: any = {}) {
  const queryClient = useQueryClient();
  const { setFeedbackAlertSuccess, setFeedbackAlertError } = useFeedbackAlerts();
  return useMutation<any, AjaxResponse, any>(updateTag, {
    ...options,
    onSuccess: (data: any, variables: any) => {
      queryClient.setQueryData(["asset", variables?.assetId], (old: any) => {
        return {
          ...(isObject(old) ? old : {}),
          tag: variables?.tag,
        };
      });
      queryClient
        .getQueryCache()
        .findAll(["assets"])
        .forEach(({ queryKey }) => {
          queryClient.setQueryData(queryKey, (data: any) => {
            const list = data?.list;
            const i = list?.findIndex((item: Asset) => item?.id === variables.assetId);
            if (i >= 0) {
              list[i] = {
                ...list[i],
                tag: variables?.tag,
              };
            }
            return data;
          });
        });
      options?.onSuccess?.();
      setFeedbackAlertSuccess("Tag updated.");
    },
    onError: (error) => setFeedbackAlertError(error?.data?.message || genericError()),
  });
}

export function useAssetsOperationHistoryInfinite(limit: number = 25, options = {}) {
  const { userType, contextId } = useAccountContext();
  const callback = userType === UserType.COST_CENTRE ? getAssetsOperationHistoryCostCentre : getAssetsOperationHistory;

  return useInfiniteQuery<GetAssetsOperationHistoryResponse>(
    "assets-operation-history-inf",
    ({ pageParam }) => callback(contextId, pageParam, limit),
    {
      getNextPageParam: (lastPage: any) => {
        return lastPage?.nextPageToken || undefined;
      },
      staleTime: 0,
      ...options,
    },
  );
}

export function usePerformLineTest() {
  return useMutation((cli: string) => performLineTest(cli));
}

export function useAssetSearch(searchTerm: string, options = {}) {
  return useInfiniteQuery(["assets-options", searchTerm], ({ pageParam }) => searchAssets(pageParam, searchTerm), {
    getNextPageParam: (response: Page<SearchAsset>) => {
      return !response.last ? response.number + 1 : undefined;
    },
    ...options,
  });
}
