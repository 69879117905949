/**
 * This enum will get empty eventually.
 * Development features die with production deployment and will get cleaned up.
 *
 * **Note:**
 * For integration testing, there must be some entries avaliable.
 * Hence this ugly way, leaking testing data into production code :(
 */
export enum DevelopmentFeature {
  GlobalBundleAlerts = "GLOBAL_BUNDLE_ALERTS",

  // Features for testing only
  FrontendIntegrationTest1 = "FRONTEND_INTEGRATION_TEST1",
  FrontendIntegrationTest2 = "FRONTEND_INTEGRATION_TEST2",
  FrontendIntegrationTest3 = "FRONTEND_INTEGRATION_TEST3",
}
