import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Main from "../../_app/layouts/Main";
import { Feature } from "../../feature/types";
import { permissionCodes, useHasPermissions } from "../../permission/hooks";
import { useContactDelete, useContacts } from "../hooks";
import { Add as AddIcon } from "../../_app/components/icons";
import ManageContactsActionButton from "../components/ManageContactsActionsButton";
import { useHasAnyFeatureEnabled } from "../../feature/hooks";
import { rowParser } from "../../_app/components/Table/helpers";
import { Contact } from "../types";
import { createStylesheet } from "../../_app/utils/styles";
import { UIAlert, UIButton, UIGrid } from "../../_app/components";
import UIDataTable, { UIDataTableColumnDef, UIDataTableState } from "../../_app/components/Table/UIDataTable";

interface TableConfig {
  title: string;
  columns: UIDataTableColumnDef[];
}

const tableConfig: TableConfig = {
  title: "Contacts",
  columns: ["First Name", "Last Name", "Main Number", "Mobile Number", "Type", "Email", { label: " ", name: "Actions" }],
};

export const ManageContacts = () => {
  const classes = useStyles();
  const navigate = useNavigate();
  const rowCountOptions = [25, 50, 100];
  const [page, setPage] = useState(0);
  const [rowCount, setRowCount] = useState(rowCountOptions[0]);

  const hasEditPermission = useHasPermissions([permissionCodes.ACCOUNT_CONTACTS, permissionCodes.ALERTS_ACCESS_FEATURE]);
  const hasEditFeature = useHasAnyFeatureEnabled(Feature.Contacts);
  const canEdit = hasEditFeature && hasEditPermission;

  const { data: contacts, isFetching } = useContacts(page, rowCount);
  useEffect(() => {
    setPage(contacts?.page || 0);
  }, [contacts]);

  const { mutate: executeUserDelete } = useContactDelete();

  const handleAdd = () => {
    navigate("/contact/new");
  };

  const handleEdit = (contact: Contact) => {
    navigate(`/contacts/${contact.id}`, { state: contact });
  };

  const handleDelete = (contact: Contact) => {
    executeUserDelete(contact);
  };

  const columns = tableConfig.columns;
  const parsed =
    contacts?.list?.map((c: Contact) => {
      return rowParser([
        c.firstName,
        c.lastName,
        c.mainNumber,
        c.mobileNumber,
        c.typeFriendly,
        c.email,
        canEdit ? <ManageContactsActionButton data={c} editAction={handleEdit} deleteAction={handleDelete} /> : undefined,
      ]);
    }) || [];

  const handleTableChange = (action: string, state: UIDataTableState) => {
    switch (action) {
      case "changePage":
        setPage(state.page);
        break;
      case "changeRowsPerPage":
        setRowCount(state.rowsPerPage);
        break;
      default:
        break;
    }
  };

  return (
    <Main
      title={
        <>
          Manage Contacts
          {canEdit && (
            <UIButton variant="contained" color="primary" data-cy="add-contact-btn" startIcon={<AddIcon />} onClick={handleAdd}>
              <span className={classes.addBtnText}>Add Contact</span>
            </UIButton>
          )}
        </>
      }
      data-cy="manage-contacts-page"
      accessPermission={[permissionCodes.ACCOUNT_VIEW_CONTACTS, permissionCodes.ALERTS_ACCESS_FEATURE]}
      feature={Feature.Contacts}
      needSelectedAccount={true}
      isLoading={isFetching}
    >
      <UIGrid className={classes.ctr}>
        <UIAlert severity="info" className={classes.info}>
          Manage your account contacts here. These are the individuals who have authority to discuss your account. You can control
          the level of authority they have by setting the appropriate Contact Type.
        </UIAlert>
        <div className={classes.tableCtr} data-cy="contacts-table">
          <UIDataTable
            title=""
            data={parsed}
            columns={columns || []}
            options={{
              onTableChange: handleTableChange,
              pagination: true,
              page,
              rowsPerPage: rowCount,
              rowsPerPageOptions: rowCountOptions,
              count: contacts?.total,
              download: false,
              elevation: 1,
              print: false,
              responsive: "standard",
              selectToolbarPlacement: "none",
              filter: false,
              sort: false,
              search: false,
              serverSide: true,
              selectableRows: "none",
              rowHover: true,
              setTableProps: () => ({ size: "small" }),
              viewColumns: false,
              setRowProps: (row) => ({ "data-cy": `contact-id-${row[4]}` }),
            }}
          />
        </div>
      </UIGrid>
    </Main>
  );
};

const useStyles = createStylesheet((theme) => ({
  ctr: {
    width: "100%",
    padding: "0",
    marginTop: theme.spacing(1.5),
  },
  tableCtr: {},
  info: {
    width: "fit-content",
    marginBottom: theme.spacing(3),
    alignItems: "center",
  },
  rowButton: {
    "& > span": {
      fontWeight: "normal",
      color: theme.palette.primary.light,
    },
  },
  addBtnText: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
}));

export default ManageContacts;
