import { Line } from "react-chartjs-2";
import { createStylesheet } from "../../../_app/utils/styles";
import { formatPrice } from "../../../billing/utils";
import { Query } from "../../../filter/types";
import { useCostByProduct, useLineChartOptions } from "../../hooks";
import GraphWidget from "./GraphWidget";

interface Props {
  queries: Query[];
}

const CostByProduct = ({ queries }: Props) => {
  const classes = useStyles();
  const { data = [], isFetching, isError } = useCostByProduct(queries);
  const hasError = isError || data?.length <= 0;

  const options = useLineChartOptions(
    data.unit,
    undefined,
    (context: any) => {
      return formatPrice({
        gross: context?.raw,
        net: context?.raw,
        vat: 0,
        unit: data.unit,
      });
    },
    true,
  );

  return (
    <GraphWidget title="Cost by Product" isLoading={isFetching} hasError={hasError} width={12}>
      <div className={classes.horizontalLineWrap}>
        <Line data={data} options={options} />
      </div>
    </GraphWidget>
  );
};

const useStyles = createStylesheet(() => ({
  horizontalLineWrap: {
    position: "relative",
    minHeight: 410,
    width: "100%",
  },
}));

export default CostByProduct;
