import { SyntheticEvent, useCallback } from "react";
import { useQuery } from "react-query";
import { AjaxResponse } from "../_app/api";
import config from "../_app/config";
import { useAccountContext } from "../_app/providers/AccountHierarchyProvider";
import { genericError } from "../_app/utils/text";
import { useHasAnyFeatureEnabled } from "../feature/hooks";
import { Feature } from "../feature/types";
import { permissionCodes, useHasPermission } from "../permission/hooks";
import {
  deleteLogicMonitorToken,
  fetchLogicMonitorToken,
  getLogicMonitorToken,
  LogicMonitorToken,
  saveLogicMonitorToken,
} from "./api";

const jsBundleUrl = config.configLogicMonitor?.jsBundleUrl;

export function useLogicMonitorAuth(options = {}) {
  const { selectedAccount } = useAccountContext();
  const accountId = selectedAccount?.id;
  return useQuery<LogicMonitorToken, AjaxResponse>(["logic-monitor-token", accountId], () => fetchLogicMonitorToken(accountId), {
    onSuccess: saveLogicMonitorToken,
    onError: deleteLogicMonitorToken,
    staleTime: 0,
    enabled: !!accountId,
    ...options,
  });
}

export function useLogicMonitor() {
  const token = getLogicMonitorToken();
  const hasPermission = useHasPermission(permissionCodes.SUPPORT_LOGIC_MONITOR);
  const hasFeature = useHasAnyFeatureEnabled(Feature.DclSupport);
  const isAuthenticated = Boolean(token && hasPermission && hasFeature);
  const { error, isError } = useLogicMonitorAuth({ enable: !isAuthenticated });
  const isReady = isAuthenticated;

  const launchInNewTab = useCallback(() => {
    if (isReady && jsBundleUrl) {
      const script = document.createElement("script");
      const div = document.createElement("div");
      script.src = jsBundleUrl;
      script.defer = true;
      div.id = "resource_monitor_app";
      const tab = window.open("", "_blank");
      if (tab) {
        tab.document.body.appendChild(div);
        tab.document.head.appendChild(script);
        tab.focus();
      }
    }
  }, [isReady]);

  const iframeProps = {
    id: "logic-monitor-iframe",
    src: "/logicmonitor.html",
    onLoad: (e: SyntheticEvent<HTMLIFrameElement, Event>) => {
      const iframe = e.target as HTMLIFrameElement;
      const script = document.createElement("script");
      script.src = jsBundleUrl || "";
      script.defer = true;
      if (iframe) {
        iframe?.contentWindow?.document.head.appendChild(script);
      }
    },
  };

  return {
    isReady,
    iframeProps,
    launchInNewTab,
    isLoading: !isAuthenticated && !isError,
    token,
    error: error || (isError && genericError()),
  };
}
