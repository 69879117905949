import { ReactNode, useEffect, useState } from "react";
import { AccountLevel } from "../../account/utils";
import { useBrandName } from "../../brand/hooks";
import AccountSelector from "../../context/components/AccountSelector";
import { useHasAnyDevelopmentFeatureEnabled } from "../../development-feature/hooks";
import { DevelopmentFeature } from "../../development-feature/types";
import { useHasAnyFeatureEnabled } from "../../feature/hooks";
import { useHasPermissions } from "../../permission/hooks";
import { useHasAccountLevelAccess } from "../../user-level/hooks";
import { UIAlert, UIGrid, UILoader, UITypography, UITypographyProps } from "../components";
import LoadingOverlay from "../components/LoadingOverlay";
import { useGoBackOrHome, useStore } from "../hooks";
import { useAccountContext } from "../providers/AccountHierarchyProvider";
import { createStylesheet, CssBaseline, CSSObject } from "../utils/styles";
import Footer from "./components/Footer";
import NavMenu, { drawerWidth } from "./components/NavMenu";
import ToolbarMenu from "./components/ToolbarMenu";
import { Feature } from "../../feature/types";
import { UserType } from "../../user-level/types";

interface MainProps {
  children: ReactNode;
  title?: string | ReactNode;
  subtitle?: string;
  titleProps?: UITypographyProps;
  contentProps?: any;
  isLoading?: boolean;
  accessPermission?: string | undefined | string[];
  feature?: Feature | Feature[];
  developmentFeature?: DevelopmentFeature;
  needSelectedAccount?: boolean;
  showNav?: boolean;
  showToolbar?: boolean;
  showFooter?: boolean;
  isModal?: boolean;
  accessGrantedCb?: Function;
  minimumAccountLevel?: AccountLevel;
  allowedUserTypes?: UserType[];
}

function Main({
  title,
  titleProps,
  subtitle,
  contentProps = {},
  isLoading,
  accessPermission = "",
  feature = [],
  developmentFeature,
  needSelectedAccount = false,
  showNav = true,
  showToolbar = true,
  showFooter = true,
  isModal = false,
  accessGrantedCb,
  children,
  minimumAccountLevel = AccountLevel.SubAccount,
  allowedUserTypes = [UserType.ACCOUNT],
  ...rest
}: MainProps) {
  const { state } = useStore();
  const goBackOrHome = useGoBackOrHome();
  const { userType, contextId } = useAccountContext();
  const brandName = useBrandName();
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = useState(false);
  const permissions =
    typeof accessPermission === "string" ? (accessPermission === "" ? [] : Array(accessPermission)) : accessPermission;
  const hasPermission = useHasPermissions(permissions, {
    refetchOnMount: true,
    staleTime: 60000,
  });
  const isUserTypeAllowed = userType && allowedUserTypes.includes(userType);

  const features = Array.isArray(feature) ? feature : Array(feature);
  const hasFeatureEnabled = useHasAnyFeatureEnabled(...features);

  const developmentFeatures = developmentFeature ? Array(developmentFeature) : Array();
  const hasDevelopmentFeatureEnabled = useHasAnyDevelopmentFeatureEnabled(...developmentFeatures);

  const hasFeature = hasDevelopmentFeatureEnabled && hasFeatureEnabled;

  const hasAccountLevelAccess = useHasAccountLevelAccess(minimumAccountLevel);

  useEffect(() => {
    document.title = `${brandName} ${typeof title === "string" ? "- " + title : ""}`;
  }, [brandName, title]);

  useEffect(() => {
    if (!hasAccountLevelAccess || isUserTypeAllowed === false) {
      goBackOrHome();
    }
  }, [hasAccountLevelAccess, isUserTypeAllowed, goBackOrHome]);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const shouldShowToolbar = showToolbar && !state.isNestedApp;
  const shouldShowFooter = showFooter && !state.isNestedApp;
  const shouldShowNav = showNav && !state.isNestedApp;

  const renderContent = () => {
    if (hasPermission && hasFeature && needSelectedAccount && !contextId) {
      return <AccountSelector />;
    }

    if (hasFeature && hasPermission && hasAccountLevelAccess && isUserTypeAllowed) {
      setTimeout(() => {
        accessGrantedCb?.();
      });
      return children;
    }
    if (hasPermission === undefined) {
      return (
        <div className={classes.loader}>
          <UILoader />
        </div>
      );
    }

    return (
      <UIGrid className={classes.error}>
        <UIAlert severity="info">You do not have permission to view this page.</UIAlert>
      </UIGrid>
    );
  };

  if (!contextId) return <LoadingOverlay />;

  return (
    <div className={classes.root} {...rest}>
      <CssBaseline />
      {shouldShowNav && <NavMenu mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />}
      <div className={`${classes.ctr} ${isModal && classes.modal}`} data-cy="main-ctr">
        {shouldShowToolbar && <ToolbarMenu handleDrawerToggle={handleDrawerToggle} />}
        <main {...contentProps} className={`${classes.main} ${contentProps.className}`}>
          {shouldShowToolbar && <div className={classes.toolbar} />}
          <UIGrid container spacing={1}>
            <UIGrid size={{ xs: 12, md: 12 }}>
              <UITypography variant="h1" className={classes.title} data-cy={"page-title"} {...titleProps}>
                {title}
              </UITypography>
              {subtitle && (
                <UITypography variant="h3" color="textSecondary">
                  {subtitle}
                </UITypography>
              )}
            </UIGrid>
            {isLoading ? (
              <div className={classes.loader}>
                <UILoader />
              </div>
            ) : (
              renderContent()
            )}
          </UIGrid>
        </main>
        {shouldShowFooter && <Footer />}
      </div>
    </div>
  );
}

const useStyles = createStylesheet((theme) => ({
  root: {
    display: "flex",
    justifyContent: "flex-end",
  },
  ctr: {
    backgroundColor: "#f0f4f8",
    flexGrow: 1,
    overflow: "hidden",
    maxWidth: `calc(100vw - ${drawerWidth}px)`,
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100vw",
    },
  },
  toolbar: theme.mixins.toolbar as CSSObject,
  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minHeight: "calc(100vh - 250px)",
    flex: 1,
  },
  main: {
    padding: theme.spacing(3),
    backgroundColor: "#ffffff",
    minHeight: "100vh",
  },
  title: {
    display: "flex",
    marginBottom: theme.spacing(1.5),
    justifyContent: "space-between",
  },
  error: {
    marginTop: theme.spacing(2),
  },
  modal: {
    "& main": {
      minHeight: 0,
    },
  },
}));

export default Main;
