import { useQuery } from "react-query";
import { AjaxResponse } from "../_app/api";
import { useStore } from "../_app/hooks";
import { getSupportContact } from "./api";
import { SupportContact } from "./types";
import { DEFAULT_BRAND } from "./utils";
import { useAccountContext } from "../_app/providers/AccountHierarchyProvider";

export function useBrand() {
  const { state } = useStore();
  return state.brand || DEFAULT_BRAND;
}

export function useBrandColors() {
  let brand = useBrand();
  return {
    primary: brand?.primaryColour,
    secondary: brand?.secondaryColour,
    tertiary: brand?.tertiaryColour,
    primary_text: brand?.primaryTextColour,
    secondary_text: brand?.secondaryTextColour,
    tertiary_text: brand?.tertiaryTextColour,
  };
}

export function useBrandName() {
  const brand = useBrand();
  return brand.name;
}

export function useLogo() {
  const brand = useBrand();
  return brand.logoUrl;
}

export function useBrandWelcomeText() {
  const brand = useBrand();
  return brand.welcomeText || "Welcome to " + brand.name;
}

export function useLoginBackground() {
  const brand = useBrand();
  return brand.loginBg;
}

export function usePromotion() {
  const brand = useBrand();
  return brand.promotion;
}

export function useFooterLinks() {
  const brand = useBrand();
  return brand.footerLinks || [];
}

export function useFooterWidget1() {
  const brand = useBrand();
  return brand.widgetArea1;
}

export function useFooterWidget2() {
  const brand = useBrand();
  return brand.widgetArea2;
}

export function usePoweredByLogo() {
  const brand = useBrand();
  return brand.poweredByImg;
}

export function useCustomerServices(options = {}) {
  const { selectedAccount } = useAccountContext();
  const accountId = selectedAccount?.id;

  return useQuery<SupportContact, AjaxResponse>(["contact", accountId], () => getSupportContact(accountId), {
    ...options,
  });
}
