export enum UserLevelFlag {
  Head = "HEAD",
  Sub = "SUB",
  Individual = "INDIVIDUAL",
}

export interface UserLevel {
  id: number;
  name: string;
  flag: UserLevelFlag;
}

export enum UserType {
  ACCOUNT = "ACCOUNT",
  COST_CENTRE = "COST_CENTRE",
}
