import api, { requiresAccountId } from "../_app/api";
import { PaymentDetails } from "./types";

export const getPaymentDetails = async (accountId: string | undefined) => {
  requiresAccountId(accountId);

  const { data }: { data: PaymentDetails } = await api({
    method: "GET",
    url: "/payment-details/" + accountId,
    context_injection: true,
  });
  return data;
};

export const postPaymentDetails = async (details: PaymentDetails, accountId: string | undefined) => {
  requiresAccountId(accountId);

  const { data }: { data: PaymentDetails } = await api({
    method: "POST",
    url: "/payment-details/" + accountId,
    data: details,
    context_injection: true,
  });
  return data;
};

export const putPaymentDetails = async (details: PaymentDetails, accountId: string | undefined) => {
  requiresAccountId(accountId);

  const { data }: { data: PaymentDetails } = await api({
    method: "PUT",
    url: "/payment-details/" + accountId,
    data: details,
    context_injection: true,
  });
  return data;
};
