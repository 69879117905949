import { useNavigate } from "react-router-dom";
import { useFeedbackAlerts } from "../../_app/hooks";
import { deleteLogicMonitorToken } from "../../logic-monitor/api";
import { SingleActionCard } from "../../_app/components/SingleActionCard";
import { Group } from "../types";
import { useAccountContext } from "../../_app/providers/AccountHierarchyProvider";
import { createStylesheet } from "../../_app/utils/styles";
import { UIGrid, UITypography } from "../../_app/components";

interface GroupCardProps {
  group: Group;
  lg: any;
}

const GroupCard = ({ group, lg, ...rest }: GroupCardProps) => {
  const { setFeedbackAlertSuccess } = useFeedbackAlerts();
  const { refreshAccountHierarchy } = useAccountContext();
  const classes = useStyles();
  const navigate = useNavigate();

  const handleSelectGroup = async () => {
    setFeedbackAlertSuccess("Selection successful");
    refreshAccountHierarchy(group.userAccessibleAccounts[0].id);
    deleteLogicMonitorToken();
    navigate("/");
  };

  return (
    <UIGrid size={{ sm: 12, md: 6, lg: lg }} className={classes.cardWrap} {...rest}>
      <SingleActionCard
        height="180px"
        actionTitle="Select"
        actionFunction={handleSelectGroup}
        actionTestId="select-group-button"
        elevation={2}
      >
        <div className={classes.cardContent}>
          <UITypography variant="h4">{group?.name}</UITypography>
        </div>
      </SingleActionCard>
    </UIGrid>
  );
};

const useStyles = createStylesheet(() => ({
  cardWrap: {
    maxWidth: "356px",
  },
  cardContent: {
    margin: "auto",
    textAlign: "center",
  },
}));

export default GroupCard;
