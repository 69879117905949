import Tabbed from "../../_app/layouts/Tabbed";
import { useHasAnyFeatureEnabled } from "../../feature/hooks";
import { Feature } from "../../feature/types";

interface Props {
  selectedTab: string;
  children: any;
}

function ManageSimActivationTabBar({ selectedTab, children }: Props) {
  return (
    <Tabbed
      selectedTab={selectedTab}
      tabs={[
        {
          value: "sim-activate",
          label: "Activate SIMs",
          hidden: !useHasAnyFeatureEnabled(Feature.SimActivations),
        },
        {
          value: "sim-disconnect",
          label: "Disconnect Mobiles",
          hidden: !useHasAnyFeatureEnabled(Feature.MobileDisconnections),
        },
      ]}
    >
      {children}
    </Tabbed>
  );
}

export default ManageSimActivationTabBar;
