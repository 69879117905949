import { UIButton, UICard, UIGrid, UITypography } from "../../../_app/components";
import { Call as CallIcon, Email as EmailIcon, Feedback as FeedbackIcon } from "../../../_app/components/icons";
import UILoader from "../../../_app/components/UILoader";
import { createStylesheet } from "../../../_app/utils/styles";
import { useBrand, useCustomerServices } from "../../../brand/hooks";
import { Brand } from "../../../brand/types";
import { useHasAnyFeatureEnabled } from "../../../feature/hooks";
import { Feature } from "../../../feature/types";
import ContactCard from "./ContactCard";

export default function CustomerService() {
  const defaultEmail = "Unknown";
  const defaultPhone = "Unknown";
  const classes = useStyles();
  const { data: services, isFetching: servicesFetching } = useCustomerServices();
  const brand: Brand | null = useBrand();
  const hasFeature = useHasAnyFeatureEnabled(Feature.DclSupport);
  if (!hasFeature) return null;
  const faqUrl = brand?.faqUrl;
  return (
    <UIGrid size={{ xs: 12 }} data-cy="contact-support-card">
      <UICard elevation={1} className={classes.card}>
        <UITypography variant="h3" gutterBottom>
          Get in touch and let us know how we can help
        </UITypography>
        {servicesFetching ? (
          <UILoader />
        ) : (
          <UIGrid container direction="row" spacing={2}>
            <ContactCard icon={<FeedbackIcon />}>
              <UIButton variant="outlined" className={classes.button}>
                <a
                  href={faqUrl || ""}
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.buttonLink}
                  data-cy="contact-support-faq-button"
                >
                  <UITypography variant="subtitle2">Take a look at our FAQs</UITypography>
                </a>
              </UIButton>
            </ContactCard>
            <ContactCard icon={<CallIcon />}>
              <UITypography variant="h4" className={classes.text}>
                CALL
              </UITypography>
              <UITypography variant="h3" className={classes.text}>
                {Boolean(services?.phone) ? services?.phone : defaultPhone}
              </UITypography>
              {Boolean(services?.phone) && <UITypography variant="body2">(Monday to Friday – 8:30am to 5:30pm)</UITypography>}
            </ContactCard>
            <ContactCard icon={<EmailIcon />}>
              <UITypography variant="h4" className={classes.text}>
                EMAIL
              </UITypography>
              <div className={classes.emailWrap}>
                <UITypography variant="h3" className={classes.emailText}>
                  {Boolean(services?.email) ? services?.email : defaultEmail}
                </UITypography>
              </div>
            </ContactCard>
          </UIGrid>
        )}
      </UICard>
    </UIGrid>
  );
}

const useStyles = createStylesheet((theme) => ({
  card: {
    padding: theme.spacing(4),
    textAlign: "center",
  },
  button: {
    padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  },
  buttonLink: {
    color: theme.palette.tertiary.main,
    textDecoration: "none",
  },
  text: {
    paddingBottom: theme.spacing(1),
  },
  emailWrap: {
    width: "100%",
  },
  emailText: {
    wordWrap: "break-word",
  },
}));
