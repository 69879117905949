import { useHasAnyFeatureEnabled } from "../../feature/hooks";
import { Feature } from "../../feature/types";
import { useStore } from "../hooks";
import { loadLiveChat } from "../lib/livechat";

function LiveChatProvider() {
  const { state } = useStore();
  const hasFeature = useHasAnyFeatureEnabled(Feature.DclSupport);
  if (hasFeature && !state.isNestedApp) loadLiveChat();
  return null;
}

export default LiveChatProvider;
