import { permissionCodes, useHasPermission } from "../../permission/hooks";
import { useHasAnyFeatureEnabled } from "../../feature/hooks";
import Tabbed from "../../_app/layouts/Tabbed";
import { getInvoicesLabel } from "../utils";
import { Feature } from "../../feature/types";

interface Props {
  selectedTab: string;
  children: any;
}

function ManageStatementTabBar({ selectedTab, children }: Props) {
  const hasStatementsFeature = useHasAnyFeatureEnabled(Feature.Statements);
  const hasInvoicesFeature = useHasAnyFeatureEnabled(Feature.Invoices);
  const hasBillingStatementPermission = useHasPermission(permissionCodes.BILLING_STATEMENT);

  const showStatementsTab = hasStatementsFeature && hasBillingStatementPermission;
  const showInvoicesTab = hasInvoicesFeature && hasBillingStatementPermission;

  return (
    <Tabbed
      selectedTab={selectedTab}
      tabs={[
        {
          value: "statements",
          label: "Statements",
          hidden: !showStatementsTab,
        },
        {
          value: "invoices",
          label: getInvoicesLabel(showInvoicesTab, showStatementsTab),
          hidden: !showInvoicesTab,
        },
      ]}
    >
      {children}
    </Tabbed>
  );
}

export default ManageStatementTabBar;
