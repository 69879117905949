import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Main from "../../_app/layouts/Main";
import { permissionCodes } from "../../permission/hooks";
import { useFeedbackAlerts } from "../../_app/hooks";
import { capitalize } from "../../_app/utils/format";
import { createStylesheet } from "../../_app/utils/styles";
import { UICard, UIGrid, UISkeleton, UITypography } from "../../_app/components";
import { Feature } from "../../feature/types";
import { UserType } from "../../user-level/types";

export const SimUnlock = () => {
  const classes = useStyles();
  const { setFeedbackAlertError } = useFeedbackAlerts();
  const navigate = useNavigate();
  const location = useLocation();
  const asset = location.state?.asset;

  useEffect(() => {
    if (!asset) {
      setFeedbackAlertError("No mobile number selected");
      navigate("/mobile-management");
    }
  }, [asset, navigate, setFeedbackAlertError]);

  const parsed = [
    { title: "Tag", val: asset?.tag || "-" },
    { title: "CLI", val: asset?.cli },
    {
      title: "SIM Number",
      val: asset?.simNumber || <UISkeleton width={150} />,
    },
    { title: "Network", val: capitalize(asset?.network) },
    { title: "PUK Code", val: asset?.pukCode || <UISkeleton width={100} /> },
  ];

  return (
    <Main
      title="SIM Unlock"
      data-cy="sim-unlock-page"
      feature={Feature.SimUnlock}
      accessPermission={permissionCodes.MOBILE_MANAGEMENT}
      showNav={false}
      showToolbar={false}
      showFooter={false}
      allowedUserTypes={[UserType.COST_CENTRE, UserType.ACCOUNT]}
    >
      <UICard hasBorder className={classes.ctr} padding="16px">
        {parsed?.map(({ title, val }, i) => {
          return (
            <UIGrid container key={`unlock-card_${title}`} className={classes.row} data-cy={`unlock-card_${i}`}>
              <UIGrid size={{ sm: 3 }}>
                <UITypography variant="h4">{title}</UITypography>
              </UIGrid>
              <UIGrid>
                <UITypography>{val || <UISkeleton width={100} />}</UITypography>
              </UIGrid>
            </UIGrid>
          );
        })}
      </UICard>
    </Main>
  );
};

const useStyles = createStylesheet((theme) => ({
  ctr: {
    flex: 1,
    marginTop: theme.spacing(1.5),
  },
  row: {
    marginBottom: "15px",
    gap: theme.spacing(1.5),
    alignItems: "center",
  },
}));

export default SimUnlock;
