import React from "react";
import { UIAlert } from "../../../_app/components";
import Tabbed from "../../../_app/layouts/Tabbed";
import { createStylesheet } from "../../../_app/utils/styles";
import { AccountLevel } from "../../../account/utils";
import { useHasAnyDevelopmentFeatureEnabled } from "../../../development-feature/hooks";
import { DevelopmentFeature } from "../../../development-feature/types";
import { useHasAccountLevelAccess } from "../../../user-level/hooks";

interface Props {
  selectedTab: string;
  children: any;
}

function ManageBundleAlertsTabBar({ selectedTab, children }: Props) {
  const classes = useStyles();
  const hasAccountAccess = useHasAccountLevelAccess(AccountLevel.Account);
  const globalBundleAlertDevelopmentFeatureEnabled = useHasAnyDevelopmentFeatureEnabled(DevelopmentFeature.GlobalBundleAlerts);

  return (
    <div className={classes.wrap}>
      {selectedTab !== "bundle-alerts-global" && (
        <>
          <UIAlert severity="info" className={classes.info}>
            Enabling bundle alerts will opt you into receiving notifications when you have nearly reached, or have reached your
            bundle allowance on your mobile. This is to help prevent bill shock and also put steps in place going forward to help
            prevent it from happening again.
          </UIAlert>
          <UIAlert severity="info" className={classes.info}>
            <strong>When we notify you</strong>
            {selectedTab === "bundle-alerts" ? (
              <>
                <br />
                An SMS will be sent to the mobile number when usage reaches 80% & 100% of the bundle allowance.
                <br />
                An email will be sent to the billing contact when any mobile number reaches 100% of the bundle allowance.
              </>
            ) : (
              <>
                <br />
                An email will also be sent to the billing contact when the usage of any Account Level Bundle reaches 80% & 100%.
              </>
            )}
          </UIAlert>
          <UIAlert severity="info" className={classes.info}>
            We will use reasonable endeavours to notify you as soon as we become aware that the mobile number has breached each
            threshold. However, in certain circumstances that are beyond our control, such as delays in call data being received
            from the network, there may be delays in our alert which may result in you exceeding your allowance. Any charges as a
            result are still liable to be paid for under your terms & conditions.
          </UIAlert>
        </>
      )}
      <Tabbed
        selectedTab={selectedTab}
        tabs={[
          { value: "bundle-alerts", label: "Individual", hidden: false },
          {
            value: "bundle-alerts-account",
            label: "Account",
            hidden: !hasAccountAccess,
          },
          {
            value: "bundle-alerts-global",
            label: "Global",
            hidden: !hasAccountAccess || !globalBundleAlertDevelopmentFeatureEnabled,
          },
        ]}
      >
        {children}
      </Tabbed>
    </div>
  );
}

const useStyles = createStylesheet((theme) => ({
  wrap: {
    flexDirection: "column",
    width: "100%",
  },
  info: {
    alignItems: "center",
    marginBottom: theme.spacing(1),
  },
}));

export default ManageBundleAlertsTabBar;
