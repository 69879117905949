import { useEffect, useState } from "react";
import { UIAlert, UIButton, UICard, UIGrid } from "../../_app/components";
import Main from "../../_app/layouts/Main";
import { createStylesheet } from "../../_app/utils/styles";
import { useHasAnyFeatureEnabled } from "../../feature/hooks";
import { Feature } from "../../feature/types";
import { permissionCodes } from "../../permission/hooks";
import DirectDebitDialog from "../components/DirectDebitDialog";
import { PaymentDialogContentField } from "../components/PaymentDialogContentField";
import { PaymentDialogContentFilled } from "../components/PaymentDialogContentFilled";
import { PaymentDialogContentUnfilled } from "../components/PaymentDialogContentUnfilled";
import { usePaymentDetails, usePaymentDetailsUpdate } from "../hooks";

export const PaymentType = () => {
  const classes = useStyles();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [accountHolderName, setAccountHolderName] = useState("");
  const [sortCode, setSortCode] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [paymentType, setPaymentType] = useState("");
  const [areDetailsEntered, setAreDetailsEntered] = useState(false);

  const { data, isLoading, isError, error } = usePaymentDetails();
  const hasDirectDebitFeature = useHasAnyFeatureEnabled(Feature.DirectDebitPayments);

  useEffect(() => {
    if (data?.accountHoldersName) {
      setAreDetailsEntered(true);
      setAccountHolderName(data?.accountHoldersName);
      setSortCode(data?.sortCode);
      setAccountNumber(data?.accountNumber);
    }
    setPaymentType(data?.paymentType || "");
  }, [data, setAreDetailsEntered]);

  const { mutate: executePaymentDetailsUpdate } = usePaymentDetailsUpdate();

  const openDialog = () => setIsDialogOpen(!isDialogOpen);

  const handleContinue = (name: string, code: string, number: string) => {
    setAreDetailsEntered(true);
    executePaymentDetailsUpdate({
      paymentType: "DIRECT_DEBIT",
      accountHoldersName: name,
      accountNumber: number,
      sortCode: code,
    });
  };
  const errorMessage = error?.status === 403 ? "Payment details cannot be amended for sub-accounts." : error?.data?.message;

  return (
    <Main
      title="Payment details"
      data-cy="payment-page"
      isLoading={isLoading}
      accessPermission={permissionCodes.ACCOUNT_PAYMENTS}
      needSelectedAccount={true}
      feature={Feature.DirectDebitPayments}
    >
      {isError ? (
        <UIGrid>
          <UIAlert severity="info">{errorMessage}</UIAlert>
        </UIGrid>
      ) : (
        <UIGrid container spacing={2} className={classes.ctr}>
          {areDetailsEntered && paymentType === "DIRECT_DEBIT" ? (
            <UIGrid size={{ xs: 12, md: 6, xl: 5 }}>
              <UICard className={classes.directDebitCardSetUp}>
                <PaymentDialogContentFilled>
                  <PaymentDialogContentField name="Account holder" value={accountHolderName} />
                  <PaymentDialogContentField name="Sort code" value={sortCode} />
                  <PaymentDialogContentField name="Account number" value={accountNumber} />
                </PaymentDialogContentFilled>
                {hasDirectDebitFeature && (
                  <UIButton
                    variant="contained"
                    color="inherit"
                    className={classes.cardButton}
                    onClick={openDialog}
                    data-cy="direct-debit-button"
                  >
                    {areDetailsEntered ? "Change Direct Debit" : "Set up Direct Debit"}
                  </UIButton>
                )}
              </UICard>
            </UIGrid>
          ) : (
            <UIGrid size={{ xs: 12, md: 6, xl: 5 }}>
              <UICard className={classes.directDebitCardSetUp}>
                <PaymentDialogContentUnfilled />
                {hasDirectDebitFeature && (
                  <UIButton
                    variant="contained"
                    color="inherit"
                    className={classes.cardButton}
                    onClick={openDialog}
                    data-cy="direct-debit-button"
                  >
                    {areDetailsEntered ? "Change Direct Debit" : "Set up Direct Debit"}
                  </UIButton>
                )}
              </UICard>
            </UIGrid>
          )}
          <DirectDebitDialog
            open={isDialogOpen}
            setOpen={setIsDialogOpen}
            onContinue={handleContinue}
            accountHolderName={accountHolderName}
            sortCode={sortCode}
            accountNumber={accountNumber}
          />
        </UIGrid>
      )}
    </Main>
  );
};

const useStyles = createStylesheet((theme) => ({
  paymentType: {
    height: "550px",
    padding: theme.spacing(6),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    textAlign: "center",
  },
  ctr: {
    width: "100%",
  },
  directDebitCardSetUp: {
    height: "550px",
    padding: theme.spacing(6),
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    color: "white",
    backgroundColor: theme.palette.tertiary.main,
    textAlign: "center",
  },
  cardButton: {
    width: "100%",
    color: theme.palette.primary.main,
    backgroundColor: "white",
  },
}));

export default PaymentType;
