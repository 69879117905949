export enum Feature {
  Advertisement = "ADVERTISEMENT",
  AssetSearch = "ASSET_SEARCH",
  AutoSwitchPacStac = "AUTO_SWITCH_PAC_STAC",
  Bars = "BARS",
  BillLimit = "BILL_LIMIT",
  BillLimitAlerts = "BILL_LIMIT_ALERTS",
  BillingAddressUpdate = "BILLING_ADDRESS_UPDATE",
  BillingCostCentreSummary = "BILLING_COST_CENTRE_SUMMARY",
  BillingDashboard = "BILLING_DASHBOARD",
  BillingNotificationEmail = "BILLING_NOTIFICATION_EMAIL",
  BillingNotificationEmailUpdate = "BILLING_NOTIFICATION_EMAIL_UPDATE",
  BillingType = "BILLING_TYPE",
  BillingTypeUpdate = "BILLING_TYPE_UPDATE",
  BundleAlerts = "BUNDLE_ALERTS",
  Communications = "COMMUNICATIONS",
  Contacts = "CONTACTS",
  DclSupport = "DCL_SUPPORT",
  DirectDebitPayments = "DIRECT_DEBIT_PAYMENTS",
  CostCentreManagement = "COST_CENTRE_MANAGEMENT",
  Invoices = "INVOICES", // TODO: Move probably to the Development Features as it looks like unfinished piece of functionality
  MobileDisconnections = "MOBILE_DISCONNECTIONS",
  SimActivations = "SIM_ACTIVATIONS",
  SimLock = "SIM_LOCK",
  SimOrder = "SIM_ORDER",
  SimSwap = "SIM_SWAP",
  SimUnlock = "SIM_UNLOCK",
  Statements = "STATEMENTS",
  UsageAlerts = "USAGE_ALERTS",
  WorldwideDataRoaming = "WORLDWIDE_DATA_ROAMING",
}
