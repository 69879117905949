import { useMutation, UseMutationOptions, useQuery } from "react-query";
import { AjaxResponse } from "../_app/api";
import {
  getBarById,
  getBarByIdCostCentre,
  getBars,
  getBarsByAssetId,
  getBarsForClis,
  getBarsForCostCentre,
  getCostCenterBarsByAssetId,
  updateBars,
  updateBarsForCostCentre,
} from "./api";
import { Bar, GetBarsResponse } from "./types";
import { useFeedbackAlerts } from "../_app/hooks";
import { genericError } from "../_app/utils/text";
import { useAccountContext } from "../_app/providers/AccountHierarchyProvider";
import { UserType } from "../user-level/types";

export function useBarsByAssetId(assetId: string, options = {}) {
  const { contextId, userType } = useAccountContext();

  return useQuery<GetBarsResponse, AjaxResponse>(
    ["barringDetails", { assetId }],
    () => {
      if (userType === UserType.COST_CENTRE) {
        return getCostCenterBarsByAssetId(contextId, assetId);
      } else {
        return getBarsByAssetId(assetId);
      }
    },
    {
      ...options,
    },
  );
}

export function useBars(params: any = {}, options = {}) {
  const { contextId, userType } = useAccountContext();

  return useQuery<GetBarsResponse["bars"], AjaxResponse>(
    ["bars", params],
    () => {
      if (userType === UserType.COST_CENTRE) {
        return getBarsForCostCentre(contextId, params);
      } else {
        return getBars(contextId, params);
      }
    },
    { ...options },
  );
}

export function useSingleBar(barId: string, assetId: string, options = {}) {
  const { contextId, userType } = useAccountContext();

  const callback =
    userType === UserType.COST_CENTRE ? () => getBarByIdCostCentre(contextId, barId, assetId) : () => getBarById(barId, assetId);

  return useQuery<Bar, AjaxResponse>([barId, assetId], callback, { ...options });
}

export function useBarsUpdate(options: UseMutationOptions = {}) {
  const { setFeedbackAlertError } = useFeedbackAlerts();
  const { contextId, userType } = useAccountContext();

  const callback = userType === UserType.COST_CENTRE ? updateBarsForCostCentre : updateBars;

  return useMutation<any, AjaxResponse, any>((params) => callback(contextId, params), {
    ...options,
    onError: (updateError) => {
      setFeedbackAlertError(updateError?.data?.message || genericError());
    },
  });
}

export function useBarsForClis(clis: any[] = [], options = {}) {
  return useQuery<any, AjaxResponse>(["cliBars", clis], () => getBarsForClis(clis), { ...options });
}
