import { UIGrid, UITypography } from "../../_app/components";
import Secondary from "../../_app/layouts/Secondary";
import { createStylesheet } from "../../_app/utils/styles";
import GroupCard from "../../account/components/GroupCard";
import { useMyAccounts } from "../../account/hooks";
import { useLogo } from "../../brand/hooks";

const Groups = () => {
  const classes = useStyles();
  const { data, isFetching } = useMyAccounts();
  const logo = useLogo();

  return (
    <Secondary data-cy="groups-selector-page" title="Group Selector" isLoading={isFetching}>
      <div className={classes.title}>
        <div
          className={classes.logo}
          style={{
            background: `url('${logo}') center center / contain no-repeat`,
          }}
        />
        <UITypography variant="subtitle1">You have access to multiple groups.</UITypography>
        <UITypography variant="subtitle1">Please select the group you want to access.</UITypography>
      </div>
      <UIGrid container direction="row" className={classes.gridContainer} spacing={2}>
        {data?.map((group) => <GroupCard key={group.id} group={group} lg={data?.length >= 3 ? 4 : 12 / data?.length} />)}
      </UIGrid>
    </Secondary>
  );
};

const useStyles = createStylesheet((theme) => ({
  gridContainer: {
    marginLeft: "auto",
    marginRight: "auto",
    maxWidth: "1128px",
    justifyContent: "center",
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down("lg")]: {
      maxWidth: "752px",
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "377px",
    },
  },
  title: {
    textAlign: "center",
    paddingBottom: theme.spacing(4),
    paddingTop: theme.spacing(4),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  logo: {
    height: "150px",
    width: "290px",
    margin: "0px 10px 30px 10px",
  },
}));

export default Groups;
