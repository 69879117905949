import api from "../_app/api";
import { convertFiltersToQueries } from "../filter/api";
import {
  AutoSwitchResult,
  GetDisconnectionAssetsResponse,
  GetDispatchedAssetsResponse,
  RequestAutoSwitchParams,
  SimDetails,
  SimSwapOperation,
} from "./types";
import { Page } from "../_app/api/types";

export const getSimDetails = async (accountId: string, simNumber: string, network: string) => {
  const { data }: { data: SimDetails } = await api({
    method: "GET",
    url: `/mobile-assets/accounts/${accountId}/sim/${simNumber}`,
    params: { network },
  });
  return data;
};

export const getSimDetailsCostCentre = async (costCentreId: string, simNumber: string, network: string) => {
  const { data }: { data: SimDetails } = await api({
    method: "GET",
    url: `/mobile-assets/cost-centers/${costCentreId}/sim/${simNumber}`,
    params: { network },
  });
  return data;
};

export const requestAutoSwitch = async (accountId: string, params: RequestAutoSwitchParams) => {
  const parsedParams = convertFiltersToQueries(params);
  const { data }: { data: string } = await api({
    method: "POST",
    url: `/mobile-assets/accounts/${accountId}/auto-switches`,
    data: parsedParams,
  });
  return data;
};

export const requestAutoSwitchCostCentre = async (costCentreId: string, params: RequestAutoSwitchParams) => {
  const parsedParams = convertFiltersToQueries(params);
  const { data }: { data: string } = await api({
    method: "POST",
    url: `/mobile-assets/cost-centres/${costCentreId}/auto-switches`,
    data: parsedParams,
  });
  return data;
};

export const cancelAutoSwitch = async (assetId: any) => {
  const { data }: { data: any } = await api({
    method: "PUT",
    url: `/mobile-assets/${assetId}/auto-switches/cancel`,
  });
  return data;
};

export const cancelAutoSwitchCostCentre = async (costCentreId: string, assetId: any) => {
  const { data }: { data: any } = await api({
    method: "PUT",
    url: `/mobile-assets/${assetId}/cost-centres/${costCentreId}/auto-switches/cancel`,
  });
  return data;
};

export const getAutoSwitchResults = async (requestId: string, page: number = 0, limit: number = 25) => {
  const { data }: { data: Page<AutoSwitchResult> } = await api({
    method: "GET",
    url: "/mobile-assets/auto-switches",
    params: {
      hubRequestId: requestId,
      page,
      size: limit,
    },
  });
  return data;
};

export const getAutoSwitchResultsCostCentre = async (
  costCentreId: string,
  requestId: string,
  page: number = 0,
  limit: number = 25,
) => {
  const { data }: { data: Page<AutoSwitchResult> } = await api({
    method: "GET",
    url: `/mobile-assets/cost-centres/${costCentreId}/auto-switches`,
    params: {
      hubRequestId: requestId,
      page,
      size: limit,
    },
  });
  return data;
};

export const getAllSimForActivation = async (page: number, limit: number) => {
  const { data }: { data: GetDispatchedAssetsResponse } = await api({
    method: "GET",
    context_injection: true,

    url: "/assets/mobile/dispatched",
    params: { page, size: limit },
  });

  return data;
};

export const getAllAssetsForAutoSwitchCancel = async (page: number, limit: number) => {
  const { data }: { data: GetDisconnectionAssetsResponse } = await api({
    method: "GET",
    context_injection: true,

    url: "/assets/mobile/disconnected",
    params: { page, size: limit },
  });

  return data;
};

export const activateSim = async (assetId: string, newSimNumber: string) => {
  const { data }: { data: any } = await api({
    method: "PUT",

    url: `/assets/${assetId}/sim/activate/`,
    params: {
      "new-sim-number": newSimNumber,
    },
  });
  return data;
};

export const performSimSwap = async (accountId: string, simData: SimSwapOperation[]) => {
  const { data }: { data: string } = await api({
    method: "PUT",
    url: `/mobile-assets/accounts/${accountId}/sim/swap`,
    data: simData,
  });
  return data;
};

export const performSimSwapCostCentre = async (costCentreId: string, simData: SimSwapOperation[]) => {
  const { data }: { data: string } = await api({
    method: "PUT",
    url: `/mobile-assets/cost-centers/${costCentreId}/sim/swap`,
    data: simData,
  });
  return data;
};
